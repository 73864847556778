// butaks-web\src\components\products\Categories.jsx
import Products from '../../datas/Products';

const calculateProductCount = (products) => {
  return products.reduce((acc, product) => {
    const normalizedCategory = product.normalizedCategory;
    acc[normalizedCategory] = (acc[normalizedCategory] || 0) + 1;
    return acc;
  }, {});
};

const productCounts = calculateProductCount(Products);

const categories = {
  "yeni-urunler": {
    title: "Yeni Ürünler - BUTAX | Spor ve Teknoloji",
    baslik: "Yeni Ürünler",
    description: "En son çıkan fitness aletlerini BUTAX ile keşfedin.",
    keywords: "yeni ürün, fitness aletleri, BUTAX, spor, teknoloji",
    productCount: productCounts['yeni-urunler'] || 0,
  },
  "urun-setleri": {
    title: "Ürün Setleri | BUTAX",
    baslik: "Ürün Setleri",
    description: "Evinizde veya spor salonunuzda tam bir fitness deneyimi için ürün setlerimizi inceleyin.",
    keywords: "ürün seti, fitness seti, BUTAX, spor, ekipman, antrenman",
    productCount: productCounts['urun-setleri'] || 0,
  },
  "gym-spor-aletleri": {
    title: "Gym Spor Aletleri | BUTAX",
    baslik: "Gym Spor Aletleri",
    description: "Evde veya spor salonunda kullanabileceğiniz en kaliteli GYM fitness aletleri BUTAX'ta.",
    keywords: "GYM, spor aletleri, fitness, halter, BUTAX, ekipman",
    productCount: productCounts['gym-spor-aletleri'] || 0,
  },
  "park-spor-aletleri": {
    title: "Park Spor Aletleri | BUTAX",
    baslik: "Park Spor Aletleri",
    description: "Açık hava spor alanlarınız için modern, işlevsel, en kaliteli ve dayanıklı spor aletleri.",
    keywords: "spor alan, spor aletleri, fitness, park, BUTAX, ekipman, açık alan,",
    productCount: productCounts['park-spor-aletleri'] || 0,
  },
  "kent-mobilyalari": {
    title: "Kent Mobilyaları | BUTAX",
    baslik: "Kent Mobilyaları",
    description: "Parklar, bahçeler ve kamu alanları için şık ve dayanıklı kent mobilyaları.",
    keywords: "kent mobilyası, park, BUTAX, şehir, bahçe, sehpa, masa",
    productCount: productCounts['kent-mobilyalari'] || 0,
  }
};

export default categories;
