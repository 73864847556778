// File: butaks-web\src\components\schemas\BreadcrumbSchema.jsx
import React from 'react';

const generateBreadcrumbSchema = (product) => {
  const baseUrl = 'https://www.butaks.com'; // Sitenizin temel URL'si

  return {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    "itemListElement": [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Ürünlerimiz",
        "item": `${baseUrl}/urunlerimiz`
      },
      {
        "@type": "ListItem",
        "position": 2,
        "name": product.category,
        "item": `${baseUrl}/urunlerimiz/${product.normalizedCategory}`
      },
      {
        "@type": "ListItem",
        "position": 3,
        "name": product.name,
        "item": `${baseUrl}/urunlerimiz/${product.normalizedCategory}/${product.slug}`
      }
    ]
  };
};

const BreadcrumbSchema = ({ product }) => {
  const schema = generateBreadcrumbSchema(product);
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default BreadcrumbSchema;
