// File: butaks-web\src\components\Products\CategoryPage.jsx
import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import ProductGrid from './ProductGrid';
import Products from '../../datas/Products';
import { Helmet } from 'react-helmet';
import { toEnglishCharacters } from '../../utils/stringUtils';
import Categories from './Categories'; // Categories.js'i import edin

const CategoryPage = () => {
  const { category } = useParams();
  const categorySlug = toEnglishCharacters(category); // URL'deki kategori slug'ı

  // Kategoriye göre ürünleri filtreleme
  const filteredProducts = Products.filter((product) =>
    toEnglishCharacters(product.category) === categorySlug
  );

  // Eşleşen kategori için SEO bilgileri
  const categorySEO = Categories[categorySlug];

  // SEO bilgileri Categories dosyasından alınır
  const seoTitle = categorySEO?.title || `${category}`;
  const seoDescription = categorySEO?.description || `${category}`;
  const seoKeywords = categorySEO?.keywords || `${category}`;
  const seoBaslik = categorySEO?.baslik || `${category}`;

  return (
    <div className='bg-white dark:bg-black'>
      <Helmet>
        <title>{seoTitle}</title>
        <meta name="description" content={seoDescription} />
        <meta property="og:title" content={seoTitle} />
        <meta property="og:description" content={seoDescription} /> {/* SEO anahtar kelimeleri ekleme */}
        <meta name="keywords" content={seoKeywords} />
      </Helmet>
      <Navbar />
      <div className='flex max-w-7xl mx-auto'>
        <div className='flex-1'>
          <div className="p-6">
              <h1 className='items-baseline inline font-bold leading-tight text-black dark:text-white text-xl sm:text-2xl lg:text-4xl xl:text-4xl mr-2'>
                 " {seoBaslik} "
                  </h1>
              <h2 className='items-baseline inline font-bold leading-tight text-black dark:text-white text-xl sm:text-2xl lg:text-4xl xl:text-4xl'>
                 araması için {filteredProducts.length}+ ürün listeleniyor. 
                  </h2>
                  <div className='mt-10'>
              {filteredProducts.length > 0 ? (
            <ProductGrid Products={filteredProducts} /> ) : ( <p>Bu kategoride ürün bulunmamaktadır.</p> )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CategoryPage;