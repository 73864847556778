// butaks-web\src\App.jsx
import { React } from 'react';
import { Routes, Route } from 'react-router-dom';
import Anasayfa from './pages/Anasayfa';
import Hakkimizda from './pages/Hakkimizda';
import Urunlerimiz from './pages/Urunlerimiz';
import CategoryPage from './components/products/CategoryPage';
import ProductDetail from './components/products/ProductDetail';
import MedyaMerkezi from './pages/MedyaMerkezi';
import Referanslar from './pages/Referanslar';
import Iletisim from './pages/Iletisim';
import Gizlilikpolitikasi from './pages/Gizlilikpolitikasi';
import Kullanimsartlari from './pages/Kullanimsartlari';
import Pagenotfound from './pages/Pagenotfound';
import * as Sentry from '@sentry/react';
const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Anasayfa />} />
      <Route path="/Hakkimizda" element={<Hakkimizda />} />
      <Route path="/Urunlerimiz" element={<Urunlerimiz />} />
      <Route path="/urunlerimiz/:category" element={<CategoryPage />} />
      <Route path="/urunlerimiz/:category/:slug" element={<ProductDetail />} />
      <Route path="/Medyamerkezi" element={<MedyaMerkezi />} />
      <Route path="/Referanslar" element={<Referanslar />} />
      <Route path="/Iletisim" element={<Iletisim />} />
      <Route path="/gizlilik-politikasi" element={<Gizlilikpolitikasi />} />
      <Route path="/kullanim-sartlari" element={<Kullanimsartlari />} />
      <Route path="*" element={<Pagenotfound />} /> {/* 404 route should typically be last */}
    </Routes>
  );
};

export default Sentry.withProfiler(App);