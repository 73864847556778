import { useGSAP } from "@gsap/react";
import { rightImg, watchImg } from "../../utils";
import dynamic from 'next/dynamic';

const LazyVideoCarousel = dynamic(() => import('../VideoCarousel'), {
    ssr: false,
    loading: () => <p>Yükleniyor...</p>,
});


const Highlights = () => {
  useGSAP(() => {
  }, []);

  return (
    <section id="highlights" className="w-screen overflow-hidden h-full common-padding bg-slate-200 dark:bg-zinc">
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-end justify-between">
          <h2 className="section-heading">Gerçek bilimsel veriler.</h2>
          <div className="flex flex-wrap items-end gap-5">
            <p className="link">
              Videolarımızı izleyin
              <img src={watchImg} alt="watch" className="ml-2" />
            </p>
            <p className="link">
              Etkinliklerimiz
              <img src={rightImg} alt="right" className="ml-2" />
            </p>
          </div>
          </div>
                <LazyVideoCarousel />
            </div>
    </section>
  );
};

export default Highlights;