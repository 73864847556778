// File: /src/components/products/ProductDetail.jsx

import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';
import products from '../../datas/Products';
import { toEnglishCharacters } from '../../utils/stringUtils';
import ProductDisplay from './ProductDisplay';
import ProductSchema from '../schemas/ProductSchema';
import BreadcrumbSchema from '../schemas/BreadcrumbSchema';
import ImageObjectSchema from '../schemas/ImageObjectSchema';

const ProductDetail = () => {
  const { category, slug } = useParams();
  const categorySlug = toEnglishCharacters(category);

  // Ürün verisinde URL'den gelen slug ve kategoriye uygun ürünü buluyoruz
  const product = products.find(
    (p) =>
      p.slug === slug &&
      toEnglishCharacters(p.category) === categorySlug
  );

  if (!product) {
    return <p>Ürün bulunamadı</p>;
  }

  return (
    <div>
      <Helmet>
        <title>{product.name} - BUTAX</title>
        <meta name="description" content={product.metaDescription} />
        <meta name="keywords" content={product.seoKeywords} />
      </Helmet>

      {/* Schema Bileşenleri */}
      <ProductSchema product={product} />
      <BreadcrumbSchema product={product} />
      <ImageObjectSchema product={product} />

      {/* Sayfa İçeriği */}
      <Navbar />
      <ProductDisplay product={product} />
      <Footer />
    </div>
  );
};

export default ProductDetail;
