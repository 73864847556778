// File: butaks-web\src\components\Products\ProductGrid.jsx
import React from 'react';
import ProductCard from './ProductCard'; // ProductCard bileşenini import et

// Ana Bileşen: Filtreli Ürün Grid'i
function ProductGrid({ Products }) { // Products prop'u varsayılan olarak tüm ürünleri içerir
  // Filtreleme artık ProductGrid'de yapılmıyor

  return (
    <section className=''>
      {/* Ürün Listesi */}
      {Products.length === 0 ? (
        <div className='text-center my-8'>
          <p className='text-gray-600 mb-4'>Bu kısımda herhangi bir ürün bulunmamaktadır. 👀</p>
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6'>
          {Products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
      )}
    </section>
  );
}

export default ProductGrid;