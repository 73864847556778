import React, { useState, useEffect } from 'react';
import validateForm from './validateForm';
import ErrorMessage from './ErrorMessage';
import ContactDetails from './ContactDetails';
import emailjs from '@emailjs/browser';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_telephone: '',
    from_email: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Gönderim sonrası kontrol
  const [stateMessage, setStateMessage] = useState(null);
  const [stateMessageColor, setStateMessageColor] = useState('text-green-500');

  // reCAPTCHA yüklemesi
  useEffect(() => {
    const loadRecaptcha = () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };
    loadRecaptcha();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // reCAPTCHA doğrulamasını al
    const recaptchaToken = grecaptcha.getResponse();
    if (!recaptchaToken) {
      setStateMessage("Lütfen reCAPTCHA doğrulamasını yapın.");
      setStateMessageColor("text-red-500");
      return;
    }

    // Form verilerini al
    const formDataObj = new FormData(e.target);
    formDataObj.append("g-recaptcha-response", recaptchaToken);

    // Formu doğrula
    const validationErrors = validateForm(formDataObj);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setStateMessage(null);
    } else {
      setErrors({});
      setIsSubmitting(true);

      emailjs
        .sendForm(
          import.meta.env.VITE_SERVICE_ID,
          import.meta.env.VITE_TEMPLATE_ID,
          e.target,
          import.meta.env.VITE_PUBLIC_KEY
        )
        .then(
          (result) => {
            setStateMessageColor("text-green-500");
            setIsSubmitting(false);
            setIsSubmitted(true); // Gönderildi olarak işaretle
            setTimeout(() => setStateMessage(null), 5000);
            e.target.reset();
            grecaptcha.reset();
          },
          (error) => {
            setStateMessage('Bir hata oluştu, lütfen tekrar deneyin.');
            setStateMessageColor("text-red-500");
            setIsSubmitting(false);
            setTimeout(() => setStateMessage(null), 5000);
          }
        );
    }
  };

  return (
    <div className='nocommon-padding'>
      <div className="my-10 grid sm:grid-cols-1 items-center gap-16 p-8 mx-auto max-w-4xl bg-slate-200 dark:bg-zinc shadow-md rounded-md text-[#333]">

        <ContactDetails />
        <form className="ml-auto space-y-4 p-8 bg-slate-300 dark:bg-zinc-800 shadow-md rounded-md max-w-4xl mx-auto" onSubmit={sendEmail}>
          {!isSubmitted && (
            <>
              <input
                type="text"
                id="adSoyad"
                name="from_name"
                placeholder="Ad Soyad"
                value={formData.from_name}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.adSoyad} />

              <input
                type="tel"
                id="telefon"
                name="from_telephone"
                placeholder="Telefon Numaranız"
                value={formData.from_telephone}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.telefon} />

              <input
                type="email"
                id="email"
                name="from_email"
                placeholder="Email"
                value={formData.from_email}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.email} />

              <input
                type="text"
                id="konu"
                name="subject"
                placeholder="Konu"
                value={formData.subject}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.konu} />

              <textarea
                id="mesaj"
                name="message"
                placeholder="Mesajınızı yazınız..."
                value={formData.message}
                onChange={handleChange}
                rows="6"
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md px-4 border text-lg pt-2.5 "
              ></textarea>
              <ErrorMessage message={errors.mesaj} />

              <div
                className="g-recaptcha "
                data-sitekey="6LcERncqAAAAAIZfd99hXQNWuQu6chykOiXz5pW5"
              ></div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full flex items-center justify-center"
              >
                {isSubmitting ? (
                  <span className="loader mr-2 border-t-2 border-white rounded-full w-5 h-5 animate-spin"></span>
                ) : (
                  'Gönder'
                )}
              </button>
            </>
          )}
          {isSubmitted && (
            <p className="text-[#007bff] text-sm mt-2">
              Mesajınız başarıyla gönderilmiştir. En kısa sürede geri dönüş yapacağımızdan emin olabilirsiniz.
            </p>
          )}
          {stateMessage && <p className={`${stateMessageColor} text-sm mt-2`}>{stateMessage}</p>}
        </form>

        <div className="relative w-full h-96 mb-4 bg-slate-100 dark:bg-white shadow-md rounded-md text-[#333] nocommon-padding">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3056.6160260312763!2d32.5075584!3d39.9946854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d32f89bf751355%3A0x9e571493744bca2e!2sYenikent%2C%2026.%20Cad.%20No%3A11%2C%2006946%20Sincan%2FAnkara!5e0!3m2!1str!2str!4v1728295943295!5m2!1str!2str"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          allowFullScreen
          aria-hidden="false"
          tabIndex="0"
        ></iframe>
      </div>
      </div>
    </div>
  );
};

export default ContactForm;
