import React from 'react'
import { heryasicinsporImg } from '../../utils';


const Ozellikdort = () => {

  return (
    <section className="nocommon-padding">
      <div className="">
        <div className="w-full">
          <h3 className="text-lg md:text-2xl font-poppins text-black font-bold leading-tight">BUTAfit</h3>
          <h2 className="text-2xl md:text-5xl font-oswald uppercase text-black font-thin leading-tight pb-10"><ab className='font-semibold'>Her yaş için</ab> özelleştirilmiş spor.</h2>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">BUTAfit ile, her yaştan ve her fiziksel yeterlilik düzeyinden insan için özelleştirilmiş spor deneyimleri sunuyoruz. Sizleri sporla buluşturmak için çalışıyoruz.</p>
          </div>
          <div class="flex-item-right">
          <img class="w-full rounded-xl" src={heryasicinsporImg} alt="Geri Dönüşüm"/>
          </div>
        </div>
        <div className="w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold">Yaş, cinsiyet veya fiziksel yeterlilik fark etmeksizin, herkese hitap eden BUTAfit spor uygulamamızı indirin, sağlığınızı güncelleyin.</p>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">BUTAfit ile, spor yapmak her zaman ve her yerde mümkün. Ürünlerimiz, farklı yaş ve fiziksel yeterlilik düzeylerine uygun olarak tasarlanmıştır.</p>
          </div>
          <div class="flex-item-right"></div>
        </div>
      </div>
    </section>

  );
} 

export default Ozellikdort;