// File: butaks-web\src\components\schemas\ImageObjectSchema.jsx
import React from 'react';

const generateImageObjectSchema = (product) => {
  const baseUrl = 'https://www.butaks.com'; // Sitenizin temel URL'si
  const licenseUrl = `${baseUrl}/license`; // Lisans bilgisi için örnek URL
  const acquireLicensePageUrl = `${baseUrl}/how-to-use-my-images`; // Lisans kullanım sayfası

  return product.images.map((image) => ({
    "@type": "ImageObject",
    "contentUrl": image, // Resmin tam URL'si
    "license": licenseUrl,
    "acquireLicensePage": acquireLicensePageUrl,
    "creditText": product.brandName || "Butaks", // Resim kredisi
    "creator": {
      "@type": "Organization",
      "name": product.brandName || "Butaks" // Ürüne özel marka adı
    },
    "copyrightNotice": product.brandName || "Butaks"
  }));
};

const ImageObjectSchema = ({ product }) => {
  const schemas = generateImageObjectSchema(product);
  return schemas.map((schema, index) => (
    <script
      key={index}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  ));
};

export default ImageObjectSchema;
