import React from 'react'
import { kentestetigiImg } from '../../utils';

const Ozellikuc = () => {

  return (
    <section className="nocommon-padding">
      <div className="">
        <div className="w-full">
          <h3 className="text-lg md:text-2xl font-poppins uppercase text-black font-bold leading-tight">Zarif ve Modern Tasarım</h3>
          <h2 className="text-2xl md:text-5xl font-oswald uppercase font-thin text-black leading-tight pb-10">Çevre ve <ab className='font-semibold'>kent estetiğine uygun</ab>.</h2>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">Modern ve minimalist tasarım anlayışımızla, parklarınızı estetik açıdan daha çekici hale getiriyoruz. Ürünlerimiz, şehrin mimari dokusuna uyum sağlayan ve estetik bir görünüm sunan, zarif ve modern bir tasarıma sahiptir.</p>
          </div>
          <div class="flex-item-right">
          <img class="w-full rounded-xl" src={kentestetigiImg} alt="Geri Dönüşüm"/>
          </div>
        </div>
        <div className="w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold">Ürünlerimizin modern ve minimalist tasarımı, parklarınızı daha çağdaş ve estetik bir hale getirir. Kullanıcı deneyimini artırırken, kentsel peyzajın güzelliğine katkı sunar.</p>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">Çevre dostu ve sürdürülebilir malzemelerden üretilen, modern ve minimalist tasarım anlayışımız ile parklarınıza estetik bir dokunuş katıyoruz. Ürünlerimiz, şehir mimarisine uyum sağlayan ve görsel olarak daha çekici bir ortam yaratan zarif hatlara sahiptir.</p>
          </div>
          <div class="flex-item-right"></div>
        </div>
      </div>
    </section>

  );
} 

export default Ozellikuc;