// File: butaks-web\src\components\Products\ProductCard.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toEnglishCharacters } from '../../utils/stringUtils';

function ProductCard({ product }) {
  const navigate = useNavigate();

  const handleTeklifAlClick = (event) => {
    event.stopPropagation(); // Tıklama olayını burada durdurarak ürün detayına yönlenmeyi engelliyoruz
    navigate('/contact'); // İletişim sayfasına yönlendirme
  };

  const handleProductClick = () => {
    const categoryInEnglish = toEnglishCharacters(product.category); // Kategoriyi İngilizce karakterlerle
    const slugInEnglish = toEnglishCharacters(product.slug); // Slug'ı İngilizce karakterlerle
    navigate(`/urunlerimiz/${categoryInEnglish}/${slugInEnglish}`);
  };

  return (
    <div 
      className='relative bg-white shadow-md rounded-lg overflow-hidden border border-gray-200' 
      onClick={handleProductClick} // Tıklanınca ürün detayına yönlendirme
    >
      {/* Üst Rozetler */}
      <div className='absolute top-2 left-2 flex gap-2'>
        {product.isBestSeller && (
          <span className='bg-orange-500 text-white px-2 py-1 rounded-full text-xs font-semibold'>
            En Çok Satan
          </span>
        )}
        {product.isAdvantageous && (
          <span className='bg-red-500 text-white px-2 py-1 rounded-full text-xs font-semibold'>
            Avantajlı Ürün
          </span>
        )}
      </div>

      {/* Ürün Görseli */}
      <img src={product.images[0]} alt={product.name} className='w-full object-cover' />

      {/* Ürün Bilgisi */}
      <div className='p-4'>
        <h3 className='text-md font-semibold text-gray-800'>{product.name}</h3>

        {/* Satıcı Puanı ve Satış Sayısı */}
        <div className='flex items-center text-sm text-gray-500 mt-2'>
          <span className='flex items-center'>
            <svg className='w-4 h-4 text-yellow-500' fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.973 1.476 8.275L12 18.896l-7.412 4.658L6.064 15.28 0 9.308l8.332-1.151z"/>
            </svg>
            {product.rating}
          </span>
          <span className='ml-2'>({product.sales}+ satış)</span>
        </div>

        {/* Fiyat Bilgisi */}
        <div className='mt-4 flex items-center'>
          {product.discountPrice ? (
            <>
              <span className='text-gray-500 line-through mr-2'>{product.originalPrice} TL</span>
              <span className='text-lg font-bold text-red-600'>{product.discountPrice} TL</span>
            </>
          ) : (
            <button onClick={handleTeklifAlClick} className='text-lg font-bold text-blue-600 underline'>Teklif Al</button>
          )}
        </div>

        {/* Alt Bilgi */}
        {product.isLowPrice && (
          <div className='mt-3 text-xs font-medium text-red-600'>
            Lansman Özel Fiyatı!
          </div>
        )}
      </div>

      {/* Alt İkonlar */}
      <div className='border-t border-gray-200 px-4 py-2 flex justify-between items-center text-sm text-gray-600'>
        {product.isVerifiedSeller && <span>✅ Yetkili Satıcı</span>}
        {product.isVideoAvailable && <span>🎥 Videolu Ürün</span>}
      </div>
    </div>
  );
}

export default ProductCard;