import {
  blackImg,
  blueImg,
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  whiteImg,
  yellowImg,
} from "../utils";

export const navLists = ["ANASAYFA", "HAKKIMIZDA", "ÜRÜNLERİMİZ", "MEDYA MERKEZİ", "REFERANSLAR", "İLETİŞİM"];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "BUTAfit'i indir.",
      "Doğru bilgiye ulaş.",
      "Hayalindeki vücuda kavuş.",
      "Çok yakında sizlerle."
    ],
    video: highlightFirstVideo,
    videoDuration: 4,
  },
  {
    id: 2,
    textLists: ["BUTAX kullanıcıları.", "Doğru. Sağlıklı. Profesyonel."],
    video: highlightSecondVideo,
    videoDuration: 5,
  },
  {
    id: 3,
    textLists: [
      "Bilimsel.",
      "Yapay Zeka Destekli.",
      "Kişiselleştirilmiş Akıllı Antrenman.",
      "Daha Hızlı Sonuçlar.",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
  },
  {
    id: 4,
    textLists: ["Tek bir tuşla.", "İster Doktorla." , "İster Koçunuzla." , "İster Diyetisyeninizle.", "Çok yakında sizlerle."],
    video: highlightFourthVideo,
    videoDuration: 3.63,
  },
];

export const models = [
  {
    id: 1,
    title: "Doğal Renk Seti",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },
  {
    id: 2,
    title: "Kırmızı Renk Seti",
    color: ["#53596E", "#6395ff", "#21242e"],
    img: blueImg,
  },
  {
    id: 3,
    title: "Mavi Renk Seti",
    color: ["#C9C8C2", "#ffffff", "#C9C8C2"],
    img: whiteImg,
  },
  {
    id: 4,
    title: "Mor Renk Seti",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
  {
    id: 5,
    title: "Sarı Renk Seti",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
  {
    id: 6,
    title: "Yeşil Renk Seti",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
  {
    id: 7,
    title: "Gri Renk Seti",
    color: ["#454749", "#3b3b3b", "#181819"],
    img: blackImg,
  },
];

export const sizes = [
  { label: '6A', value: "small" },
  { label: '8AB', value: "medium" },
  { label: '12CD', value: "large" },
];

export const footerLinks = [
  "Gizlilik Politikası",
  "Kullanım Şartları",
  "Site Map",
  "Şirket Bilgileri",
];