import React from 'react';

const OrganizationSchema = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "BUTAX SPOR ALETLERİ VE KENT MOBİLYALARI YAZILIM ANONİM ŞİRKETİ",
    "legalName": "BUTAX BUILDER OF THE ULTIMATE TRAINING AREAS SPOR ALETLERİ VE KENT MOBİLYALARI YAZILIM ANONİM ŞİRKETİ",
    "alternateName": "BUTAKS",
    "url": "https://www.butaks.com",
    "logo": "/assets/svg/butaks.svg", // public klasöründeki logo için    "description": "Açık ve kapalı alan spor aletleri, kardiyo ekipmanları, spor giyim, park ve bahçe mobilyaları, bilgisayar yazılım ve donanım hizmetleri sunuyoruz.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "MUSTAFA KEMAL MAH. 26 CAD. MESA NO: 11",
      "addressLocality": "SİNCAN",
      "addressRegion": "ANKARA",
      "postalCode": "06946", // Posta kodunu ekleyin
      "addressCountry": "TR"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+90-530-660-20-24", // Telefon numaranızı ekleyin
      "email": "info@butaks.com", // E-posta adresinizi ekleyin
      "contactType": "Müşteri Hizmetleri"
    },
    "foundingDate": "2024-11-07", // Kuruluş tarihini YYYY-AA-GG formatında ekleyin
    "sameAs": [
      "SOSYAL MEDYA LİNK 1", // Sosyal medya hesaplarınızın linklerini ekleyin
      "SOSYAL MEDYA LİNK 2",
      // ...
    ],
    "numberOfEmployees": {
    "@type": "QuantitativeValue",
    "minValue": 2,
    "maxValue": 20
    },
        "hasMerchantReturnPolicy": {
            "@type": "MerchantReturnPolicy",
            "applicableCountry": ["TR"],
            "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow", // veya MerchantReturnUnlimitedWindow, MerchantReturnNotPermitted
            "returnPolicyCountry":"TR",
            "merchantReturnDays":"30",
            "customerRemorseReturnFees": "https://schema.org/OriginalShippingFees", // veya FreeReturn, ReturnFeesCustomerResponsibility, RestockingFees
            "customerRemorseReturnLabelSource": "https://schema.org/ReturnLabelCustomerResponsibility", // veya ReturnLabelDownloadAndPrint, ReturnLabelInBox
            "refundType": "https://schema.org/FullRefund", // veya StoreCreditRefund, ExchangeRefund
            "returnMethod":["https://schema.org/ReturnByMail", "https://schema.org/ReturnInStore"], // İade yöntemleri
            "itemCondition":["https://schema.org/NewCondition"]
        }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default OrganizationSchema;