import hero from "/assets/images/hero.jpeg";

export const heroImg = hero;

//MP4
import butakstanitimmv from "/assets/videos/butakstanitim.mp4";
import butaksmobiletanitimmv from "/assets/videos/butaksmobiletanitim.mp4";
import highlightFirstmv from "/assets/videos/highlight-first.mp4";
import highlightSectmv from "/assets/videos/hightlight-sec.mp4";
import highlightThirdmv from "/assets/videos/hightlight-third.mp4";
import highlightFourthmv from "/assets/videos/hightlight-fourth.mp4";
import exploremv from "/assets/videos/explore.mp4";
import framemv from "/assets/videos/frame.mp4";

//SVG
import butaks from "/assets/svg/butaks.svg"; 
import butaksdark from "/assets/svg/butaksdark.svg";
import butakssec from "/assets/svg/butakssec.svg";
import arama from "/assets/svg/arama.svg";
import aramadark from "/assets/svg/aramadark.svg";
import magaza from "/assets/svg/magaza.svg";
import magazadark from "/assets/svg/magazadark.svg";
import watch from "/assets/svg/watch.svg";
import right from "/assets/svg/right.svg";
import replay from "/assets/svg/replay.svg";
import play from "/assets/svg/play.svg";
import pause from "/assets/svg/pause.svg";
import telefonno from "/assets/svg/telefonno.svg";
import email from "/assets/svg/email.svg";
import location from "/assets/svg/location.svg";
import facebook from "/assets/svg/facebook.svg";
import linkedin from "/assets/svg/linkedin.svg";
import instagram from "/assets/svg/instagram.svg";
import youtube from "/assets/svg/youtube.svg";
import x from "/assets/svg/x.svg";
import website from "/assets/svg/website.svg";
import cargo from "/assets/svg/cargo.svg"

//JPG,PNG
import yellow from "/assets/images/yellow.jpg";
import blue from "/assets/images/blue.jpg";
import white from "/assets/images/white.jpg";
import black from "/assets/images/black.jpg";
import explore1 from "/assets/images/explore1.png";
import explore2 from "/assets/images/explore2.jpg";
import ozellik1 from "/assets/images/geridonusum.png";
import ozellik2 from "/assets/images/vandalizm.png";
import ozellik3 from "/assets/images/kent-estetigi.png";
import ozellik4 from "/assets/images/her-yas-icin-spor.png";
import ozellik5 from "/assets/images/sporda-yapay-zeka.png";
import hakkimizda from "/assets/images/Butax-Firma-Tabelasi.webp";
import butaxapp from "/assets/images/BUTAfit.png";
import placeholder from "/assets/images/squareplaceholder.jpg";
import frame from "/assets/images/frame.png";
import bp1 from "/assets/images/modern-park-spor-aleti-bench-press-1.png";
import bp2 from "/assets/images/modern-park-spor-aleti-bench-press-2.png";
import bp3 from "/assets/images/modern-park-spor-aleti-bench-press-3.png";

//WEBP
import tubitak from "/assets/images/Tubitak-referans-butaks.webp";
import Ankaraticaretodasi from "/assets/images/Ankara-ticaret-odasi-referanslar-butaks.webp";
import simpleproduct from "/assets/webp/simpleproduct.webp";

export const butakstanitimVideo = butakstanitimmv;
export const butaksmobiletanitimVideo = butaksmobiletanitimmv;
export const highlightFirstVideo = highlightFirstmv;
export const highlightSecondVideo = highlightSectmv;
export const highlightThirdVideo = highlightThirdmv;
export const highlightFourthVideo = highlightFourthmv;
export const exploreVideo = exploremv;
export const frameVideo = framemv;

export const butaksImg = butaks;
export const butaksdarkImg = butaksdark;
export const butakssecImg = butakssec;
export const aramaImg = arama;
export const aramadarkImg = aramadark;
export const magazaImg = magaza;
export const magazadarkImg = magazadark;
export const watchImg = watch;
export const rightImg = right;
export const replayImg = replay;
export const playImg = play;
export const pauseImg = pause;
export const telefonnoImg = telefonno;
export const emailImg = email;
export const locationImg = location;
export const facebookImg = facebook;
export const linkedinImg = linkedin;
export const instagramImg = instagram;
export const youtubeImg = youtube;
export const xImg = x;
export const websiteImg = website;
export const cargoImg = cargo;


export const yellowImg = yellow;
export const blueImg = blue;
export const whiteImg = white;
export const blackImg = black;
export const explore1Img = explore1;
export const explore2Img = explore2;
export const geridonusumImg = ozellik1;
export const vandalizmImg = ozellik2;
export const kentestetigiImg = ozellik3;
export const heryasicinsporImg = ozellik4;
export const spordayapayzekaImg = ozellik5;
export const hakkimizdaImg = hakkimizda;
export const butaxappImg = butaxapp;
export const frameImg = frame;
export const squarePlaceholder = placeholder;
export const bp1Img = bp1;
export const bp2Img = bp2;
export const bp3Img = bp3;


export const tubitakImg = tubitak;
export const atoImg = Ankaraticaretodasi;
export const sp = simpleproduct;