import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Referanslarimiz from '../components/references/Referanslarimiz';
import { Helmet } from 'react-helmet';

const Referanslar = () => {
  return (
    <div className="bg-white dark:bg-black flex flex-col min-h-screen">
        <Helmet>
        <title>Referanslarımız - BUTAX | Spor ve Teknoloji</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
        </Helmet>
        <Navbar />
        {/* Hakkımızda sayfası içeriği */}
       <main className="flex-grow overflow-y-auto">
       <Referanslarimiz />
       </main>
        {/* Hakkımızda sayfası içeriği */}
       <Footer />
    </div>
  );
};
  export default Referanslar;