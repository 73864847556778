import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Contactmain from '../components/contact/Contactmain'
import { Helmet } from 'react-helmet';
import LocalBusinessSchema from '../components/schemas/LocalBusinessSchema';

const Iletisim = () => {
    return (
      <div className="bg-white dark:bg-black">
        <Helmet>
        <title>İletişim | BUTAX</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>
      <LocalBusinessSchema />
        <Navbar />
        {/* Hakkımızda sayfası içeriği */}
        <main className="flex-grow overflow-y-auto">
        <Contactmain />
        </main>
        {/* Hakkımızda sayfası içeriği */}
        <Footer />
      </div>
    );
  };
  export default Iletisim;