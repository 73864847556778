import Navbar from '../components/Navbar';
import Hero from '../components/homepage/Hero';
import Highlights from '../components/homepage/Highlights';
import Features from '../components/features/Features';
import HowItWorks from '../components/homepage/HowItWorks';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import OrganizationSchema from '../components/schemas/OrganizationScheme';

const Anasayfa = () => {
  return (
    <div className="bg-white dark:bg-black flex flex-col min-h-screen">
      <Helmet>
        <title>Dış Mekan Spor Aletleri & Park Spor Aletleri | BUTAX</title>
        <meta name="description" content="BUTAX, açık alan ve kapalı alan fitness aletleri üreten, spor ve teknolojiyi birleştiren yenilikçi bir markadır. Sporu herkes için daha erişilebilir, keyifli ve verimli hale getirmeyi hedefliyoruz." />
        <meta name="keywords" content="BUTAX, fitness, spor ekipmanları, açık alan fitness, kapalı alan fitness, açık alan fitness ekipmanları satın al," />
      </Helmet>
      <OrganizationSchema />
      <Navbar />
      <main className="flex-grow overflow-y-auto">
      <Hero />
      <Highlights />
      {/* model yapılacak */}
      <Features />
      <HowItWorks />
      </main>
      <Footer />
      </div>

  );
};

export default Anasayfa;
