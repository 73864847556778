import React from 'react';

const LocalBusinessSchema = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "BUTAX SPOR ALETLERİ VE KENT MOBİLYALARI YAZILIM ANONİM ŞİRKETİ",
    "image": [
      "/assets/images/Butax-Firma-Tabelasi.webp" // Resim URL'lerinizi güncelleyin
    ],
    "description": "Açık ve kapalı alan spor aletleri, kardiyo ekipmanları, spor giyim, park ve bahçe mobilyaları, bilgisayar yazılım ve donanım hizmetleri sunuyoruz.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "MUSTAFA KEMAL MAH. 26 CAD. MESA NO: 11",
      "addressLocality": "SİNCAN",
      "addressRegion": "ANKARA",
      "postalCode": "06946",
      "addressCountry": "TR"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "39.9956531", // Enlem koordinatını ekleyin
      "longitude": "32.5073886" // Boylam koordinatını ekleyin
    },
    "url": "https://www.butaks.com",
    "telephone": "+90-530-660-20-24",
    "priceRange": "$$",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": [
          "Monday", "Tuesday", "Wednesday", "Thursday", "Friday"
        ],
        "opens": "08:00",
        "closes": "18:00"
      }
      // Diğer günler için de ekleyin
    ],
    "hasMerchantReturnPolicy": {
      "@type": "MerchantReturnPolicy",
      "applicableCountry": ["TR"],
      "returnPolicyCategory": "https://schema.org/MerchantReturnFiniteReturnWindow",
      "returnPolicyCountry": "TR",
      "merchantReturnDays": "30",
      "customerRemorseReturnFees": "https://schema.org/OriginalShippingFees",
      "customerRemorseReturnLabelSource": "https://schema.org/ReturnLabelCustomerResponsibility",
      "refundType": "https://schema.org/FullRefund",
      "returnMethod": ["https://schema.org/ReturnByMail", "https://schema.org/ReturnInStore"],
      "itemCondition": ["https://schema.org/NewCondition"]
    }
  };

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default LocalBusinessSchema;