// src/utils/validateForm.jsx
export const validateForm = (formData) => {
  let errors = {};

  // FormData nesnesinden değerleri al
  const adSoyad = formData.get('from_name');
  const telefon = formData.get('from_telephone');
  const email = formData.get('from_email');
  const konu = formData.get('subject');
  const mesaj = formData.get('message');

  // Değerleri doğrula
  if (!adSoyad) errors.adSoyad = "Ad Soyad alanı zorunludur.";
  if (!telefon) errors.telefon = "Telefon numarası zorunludur.";
  if (!email) {
    errors.email = "Email zorunludur.";
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = "Geçerli bir email adresi giriniz.";
  }
  if (!konu) errors.konu = "Konu zorunludur.";
  if (!mesaj) errors.mesaj = "Mesaj alanı boş olamaz.";

  return errors;
};

export default validateForm;