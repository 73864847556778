import React, { useState } from 'react';
import { geridonusumImg, vandalizmImg, heryasicinsporImg, kentestetigiImg, spordayapayzekaImg } from '../../utils';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Ozellikbir from './Ozellikbir';
import Ozellikiki from './Ozellikiki';
import Ozellikuc from './Ozellikuc';
import Ozellikdort from './Ozellikdort';
import Ozellikbes from './Ozellikbes';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import NpImageObjectSchema from '../schemas/NpImageObjectSchema';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const Features = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxContent, setLightboxContent] = useState({
    title: '',
    description: ''
  });

  const features = [
    {
      id: 1,
      title3: 'Eskiyi Yeniye Dönüştürüyoruz',
      title4: 'Geri dönüştürülerek elde edilen ürünler.',
      image: geridonusumImg,
      description: <Ozellikbir />,
    },
    {
      id: 2,
      title3: 'Uzun Ömürlü',
      title4: 'Vandalizme ve çevre şartlarına karşı dirençli.',
      image: vandalizmImg,
      description: <Ozellikiki />,
    },
    {
      id: 3,
      title2: 'Zarif ve Modern Tasarım',
      title: 'Çevre ve kent estetiğine uygun.',
      image: kentestetigiImg,
      description: <Ozellikuc />,
    },
    {
      id: 4,
      title4: 'BUTAfit',
      title3: 'Her yaş için özelleştirilmiş spor.',
      image: heryasicinsporImg,
      description: <Ozellikdort />,
    },
    {
      id: 5,
      title2: 'Yapay Zeka Destekli',
      title: 'Bu yapay zeka ne yapay?',
      image: spordayapayzekaImg,
      description: <Ozellikbes />,
    },
  ];

  const handleFeatureClick = (feature) => {
    setLightboxContent({
      title: feature.title,
      description: feature.description
    });
    setLightboxOpen(true);
  };

  const handleLightboxClose = () => {
    setLightboxOpen(false);
  };

  return (
    <section className="common-padding bg-slate-200 dark:bg-zinc relative overflow-hidden">
      <div>
        <Swiper
        spaceBetween={10} //default
        slidesPerView={4} //default
        breakpoints={{
          340: {
            slidesPerView: 2,
            spaceBetween: 250,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: -170,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 150,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: -100,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 100,
          },
          1600: {
            slidesPerView: 5,
            spaceBetween: -10,
          },
          1900: {
            slidesPerView: 5,
            spaceBetween: -10,
          },
        }}
          pagination={false}
          lazy={true}
          preloadimages="true"
          autoplay={{ delay: 8000 }}
          loop={true}
          navigation={{
            nextEl: '.sbn',
            prevEl: '.sbp',
          }}
          className="mySwiper"
        >
          {features.map((feature) => (
            <SwiperSlide key={feature.id}>
              <div
                className="rounded-3xl shadow-md p-2 bg-cover bg-center cursor-pointer w-[260px] h-[505px] sm:w-[344px] sm:h-[656px]"
                style={{ backgroundImage: `url(${feature.image})` }}
                onClick={() => handleFeatureClick(feature)}
              >
                <div className="flex flex-col my-4">
                  <h3 className="ml-2 text-md font-semibold text-white">{feature.title2}</h3>
                  <h2 className="ml-2 text-2xl font-semibold text-white">{feature.title}</h2>
                  <h3 className="ml-2 text-md font-semibold text-black">{feature.title3}</h3>
                  <h2 className="ml-2 text-2xl font-semibold text-black">{feature.title4}</h2>
                </div>
                  {/* ImageObjectSchema */}
                  <NpImageObjectSchema
                  images={[feature.image]}
                  url="/" // public klasörü için
                  brandName="BUTAX"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {lightboxOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 px-5" style={{ backdropFilter: 'blur(10px)' }}>
          <div className="bg-white rounded-l-3xl shadow-xl px-2 max-w-6xl w-full relative" style={{ maxHeight: '90vh', overflowY: 'auto' }}>
            <button onClick={handleLightboxClose} className="bg-gray-100 bg-opacity-50 rounded-full absolute py-2 px-2 top-4 right-4 text-gray-600 hover:text-gray-800 focus:outline-none">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </button>
            <p className="text-gray-600 pt-10">{lightboxContent.description}</p>
          </div>
        </div>
      )}

      <div className="absolute pr-28 bottom-20 right-10 sm:right-40 flex">
        <button className="sbp rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="15 18 9 12 15 6"></polyline></svg>
        </button>
        <button className="sbn rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="9 18 15 12 9 6"></polyline></svg>
        </button>
      </div>
    </section>
  );
};

export default Features;