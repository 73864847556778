import React, { useState, useEffect } from 'react';
import validateForm from '../contact/validateForm';
import ErrorMessage from '../contact/ErrorMessage';
import emailjs from '@emailjs/browser';

const Quoteform = ({ product }) => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_telephone: '',
    from_email: '',
    subject: `${product?.name || ''} ${product?.productCode ? `[${product?.productCode}]` : ''} - Fiyat Teklifi İstiyorum`,
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // Gönderim sonrası kontrol
  const [stateMessage, setStateMessage] = useState(null);
  const [stateMessageColor, setStateMessageColor] = useState('text-green-500');

  // reCAPTCHA yüklemesi
  useEffect(() => {
    const loadRecaptcha = () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };
    loadRecaptcha();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // reCAPTCHA doğrulamasını al
    const recaptchaToken = grecaptcha.getResponse();
    if (!recaptchaToken) {
      setStateMessage("Lütfen reCAPTCHA doğrulamasını yapın.");
      setStateMessageColor("text-red-500");
      return;
    }

    // Form verilerini al
    const formDataObj = new FormData(e.target);
    formDataObj.append("g-recaptcha-response", recaptchaToken);

    // Formu doğrula
    const validationErrors = validateForm(formDataObj);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setStateMessage(null);
    } else {
      setErrors({});
      setIsSubmitting(true);

      emailjs
        .sendForm(
          import.meta.env.VITE_SERVICE_ID,
          import.meta.env.VITE_TEMPLATE_ID,
          e.target,
          import.meta.env.VITE_PUBLIC_KEY
        )
        .then(
          (result) => {
            setStateMessageColor("text-green-500");
            setIsSubmitting(false);
            setIsSubmitted(true); // Gönderildi olarak işaretle
            setTimeout(() => setStateMessage(null), 5000);
            e.target.reset();
            grecaptcha.reset();
          },
          (error) => {
            setStateMessage('Bir hata oluştu, lütfen tekrar deneyin.');
            setStateMessageColor("text-red-500");
            setIsSubmitting(false);
            setTimeout(() => setStateMessage(null), 5000);
          }
        );
    }
  };

  return (
      <div className="pb-10">
        <form className="ml-auto space-y-4 p-8 bg-slate-300 dark:bg-zinc-800 shadow-md rounded-md max-w-4xl mx-auto" onSubmit={sendEmail}>
          {!isSubmitted && (
            <>
              <input
                type="text"
                id="adSoyad"
                name="from_name"
                placeholder="Ad Soyad"
                value={formData.from_name}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.adSoyad} />

              <input
                type="tel"
                id="telefon"
                name="from_telephone"
                placeholder="Telefon Numaranız"
                value={formData.from_telephone}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.telefon} />

              <input
                type="email"
                id="email"
                name="from_email"
                placeholder="Email"
                value={formData.from_email}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.email} />

              <textarea
                type="text"
                id="konu"
                name="subject"
                placeholder="Konu"
                value={formData.subject}
                onChange={handleChange}
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md py-2.5 px-4 border text-lg"
              />
              <ErrorMessage message={errors.konu} />

              <textarea
                id="mesaj"
                name="message"
                placeholder="Mesajınızı yazınız..."
                value={formData.message}
                onChange={handleChange}
                rows="3"
                className="w-full bg-white dark:bg-[#121212] font-poppins font-black text-zinc dark:text-[#fff] rounded-md px-4 border text-lg pt-2.5 "
              ></textarea>
              <ErrorMessage message={errors.mesaj} />

              <div
                className="g-recaptcha"
                data-sitekey="6LcERncqAAAAAIZfd99hXQNWuQu6chykOiXz5pW5"
              ></div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="text-white bg-[#007bff] hover:bg-blue-600 font-semibold rounded-md text-sm px-4 py-2.5 w-full flex items-center justify-center"
              >
                {isSubmitting ? (
                  <span className="loader mr-2 border-t-2 border-white rounded-full w-5 h-5 animate-spin"></span>
                ) : (
                  'Gönder'
                )}
              </button>
            </>
          )}
          {isSubmitted && (
            <p className="text-[#007bff] text-sm mt-2">
              Mesajınız başarıyla gönderilmiştir. En kısa sürede geri dönüş yapacağımızdan emin olabilirsiniz.
            </p>
          )}
          {stateMessage && <p className={`${stateMessageColor} text-sm mt-2`}>{stateMessage}</p>}
        </form>
      </div>
  );
};

export default Quoteform;
