// butaks-web/src/components/contact/ContactDetails.jsx
import React from 'react';
import { telefonnoImg, emailImg, locationImg, facebookImg, instagramImg, linkedinImg, youtubeImg } from '../../utils';

const ContactDetails = () => {
  return (
    <div>
      <div>
        <h1 className="h1-heading uppercase font-bold">Bize Ulaşın</h1>
        <p className="text-md text-black dark:text-gray-400 mt-3">
          BUTAX ile ilgili sorularınız veya işbirliği fırsatlarınız için bize ulaşmaktan çekinmeyin.
        </p>

        <div className="mt-12">
          <h2 className="text-xl font-bold font-oswald text-black dark:text-white uppercase ">Adres</h2>
          <ul className="mt-3">
            <li className="flex items-center">
              <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <img src={locationImg} alt="Butaks Adres" />
              </div>
              <a
                target="blank"
                href="https://www.google.com/maps/place/Yenikent,+26.+Cad.+No:11,+06946+Sincan%2FAnkara"
                className="text-[#007bff] text-sm ml-3"
              >
                <small className="block">Türkiye / Ankara</small>
                <strong>Yenikent, Mustafa Kemal Mh, 26. Cad. No:11, 06946 Sincan/Ankara</strong>
              </a>
            </li>
          </ul>

          <h2 className="text-xl font-bold font-oswald text-black dark:text-white uppercase mt-5">Telefon</h2>
          <ul className="mt-3">
            <li className="flex items-center">
              <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <img src={telefonnoImg} alt="Telefon İkonu" />
              </div>
              <a href="tel:+903129114859" className="text-[#007bff] text-sm ml-3">
                <strong>+90 312 991 48 59</strong>
              </a>              
              <a href="tel:+905306602024" className="block text-[#007bff] text-sm ml-3">
              <strong>+90 530 660 20 24</strong>
              </a>
            </li>
          </ul>

          <h2 className="text-xl font-bold font-oswald text-black dark:text-white uppercase mt-5">Email</h2>
          <ul className="mt-3">
            <li className="flex items-center">
              <div className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <img src={emailImg} alt="Butax Email" />
              </div>
              <a href="mailto:info@butax.com" className="text-[#007bff] text-sm ml-3">
                <small className="block">Mail</small>
                <strong>info@butax.com</strong>
              </a>
            </li>
          </ul>
        </div>

        <div className="mt-12">
          <h2 className="text-lg font-bold font-oswald dark:text-white uppercase mt-5">Sosyal Medya</h2>
          <ul className="flex mt-3 space-x-4">
            <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href="https://facebook.com">
                <img src={facebookImg} alt="Butax Facebook" />
              </a>
            </li>
            <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href="https://linkedin.com">
                <img src={linkedinImg} alt="Butax LinkedIn" />
              </a>
            </li>
            <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href="https://instagram.com">
                <img src={instagramImg} alt="Butax Instagram" />
              </a>
            </li>
            <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
              <a href="https://youtube.com">
                <img src={youtubeImg} alt="Butax YouTube" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
