import React from 'react'
import { geridonusumImg } from '../../utils';

const Ozellikbir = () => {

  return (
    <section className="nocommon-padding">
      <div className="">
        <div className="w-full">
          <h3 className="text-lg md:text-2xl font-poppins uppercase text-black font-bold leading-tight"> Eskiyi Yeniye Dönüştürüyoruz </h3>
          <h2 className="text-2xl md:text-5xl font-oswald font-thin uppercase text-black leading-tight pb-10"> <ab className='font-semibold'>Geri dönüştürülerek</ab> elde edilen ürünler. </h2>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4"> Sürdürülebilirlik vizyonumuzla, eski spor aletlerini geri dönüştürerek yeni ve dayanıklı ürünlere dönüştürüyoruz. Bu sayede çevreyi korurken, spor alanlarını daha modern ve işlevsel hale getiriyoruz.</p>
          </div>
          <div class="flex-item-right">
          <img class="w-full rounded-xl" src={geridonusumImg} alt="Geri Dönüşüm"/>
          </div>
        </div>
        <div className="w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold"> Parklarımızdaki eski spor aletlerinin yerine, geri dönüştürülmüş malzemelerden üretilen, daha kaliteli ve estetik ürünler sunuyoruz. Bu yaklaşım, hem çevreye hem de spor alanlarının estetiğine olumlu katkı sağlıyor.</p>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4"> Geri dönüşüm ile sporu birleştiriyoruz. Eskimiş spor aletlerini toplayarak, yeni ve dayanıklı ürünlere dönüştürüyoruz. Bu, çevresel sürdürülebilirliğe olan bağlılığımızı gösterirken, aynı zamanda spor alanlarının daha modern ve kullanılabilir olmasını sağlar.</p>
          </div>
          <div class="flex-item-right"></div>
        </div>
      </div>
    </section>

  );
} 

export default Ozellikbir;