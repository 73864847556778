import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import { toEnglishCharacters } from "../../utils/stringUtils";
import Tooltip from "../Tooltip";
import Quoteform from "./Quoteform"

const ProductDisplay = ({ product }) => {
  const swiperRef = useRef(null);
  const thumbsSwiper = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleThumbMouseOver = (index) => {
    if (swiperRef.current && swiperRef.current.slideTo) {
      swiperRef.current.slideTo(index);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return { truncated: `${text.substring(0, maxLength)}...`, full: text };
    }
    return { truncated: text, full: null };
  };

  
  const [lightboxOpen, setLightboxOpen] = useState(false);


  return (
    <section className="bg-white dark:bg-black">
      <div className="max-w-7xl max-lg:max-w-2xl mx-auto container p-4 flex flex-col md:flex-row gap-6">
        <nav aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2">
            <li>
              <a href="/urunlerimiz" className="text-gray-500 hover:text-gray-700">Ürünlerimiz</a>
            </li>
            <span className="text-black dark:text-white">{'>'} </span>
            <li>
              <a href={`/urunlerimiz/${toEnglishCharacters(product.category)}`} className="text-gray-500 hover:text-gray-700"> {product.category} </a>
            </li>
            <span className="text-black dark:text-white">{'>'} </span>
            <li>
              <span aria-current="page" className="font-medium text-gray-900 dark:text-white"> {product.name} </span>
            </li>
          </ol>
        </nav>
      </div>


      <div className="max-w-7xl max-lg:max-w-2xl mx-auto container p-4 flex flex-col md:flex-row gap-8">   
          
      <div className="w-full md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-4/12">
    <Swiper
      breakpoints={{
        340: { allowTouchMove: true, speed: 300 },
        640: { allowTouchMove: true, speed: 300 },
        1024: { allowTouchMove: false, speed: 300 },
      }}
      slidesPerView={1}
      spaceBetween={30}
      lazy={true}
      thumbs={{ swiper: thumbsSwiper.current }}
      modules={[Pagination, Navigation, Thumbs]}
      onSwiper={(swiper) => {
        swiperRef.current = swiper; // Swiper referansını güncelledik
      }}
      onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
      className="mySwiper"
    >
      {/* Önceki Buton */}
      <button
        className="swiper-button-prev"
        onClick={() => swiperRef.current?.slidePrev()} // Önceki slayta geçiş
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
      </button>
      {/* Sonraki Buton */}
      <button
        className="swiper-button-next"
        onClick={() => swiperRef.current?.slideNext()} // Sonraki slayta geçiş
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
      </button>

      {(product?.images || []).map((img, index) => (
        <SwiperSlide key={index}>
          <img
            src={img}
            alt={`${product?.name || "Resim Bulunamadı "} ${index + 1}`}
            className="rounded-lg w-full h-auto object-cover border border-slate-300 dark:border-white"
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
    <Swiper
      onSwiper={(swiper) => (thumbsSwiper.current = swiper)}
      spaceBetween={10}
      slidesPerView={5}
      freeMode={true}
      lazy={true}
      modules={[Thumbs]}
      className="swiper-thumbs"
    >
      {(product?.images || []).map((img, index) => (
        <SwiperSlide
          className="pt-5"
          key={index}
          onMouseOver={() => handleThumbMouseOver(index)}
        >
          <img
            src={img}
            alt={`${product?.name || "Resim Bulunamadı "} ${index + 1}`}
            className={`rounded-xl w-full h-auto object-cover border ${
              activeIndex === index ? "border-orange-500" : "border-slate-300"
            } hover:border-[3px] hover:border-orange-500`}
            style={{ width: "70px", height: "100px", objectFit: "cover" }}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
        
        {/* Ürün Bilgileri */} <div className="w-full md:w-1/2 flex flex-col gap-4">
          <h1 className="product-heading">{product?.name || 'Ürün İsmi'}</h1>
          
          
          {/* Fiyat ve Satıcı Puanı */} <div className="flex items-center">
            <span className="text-xl text-red-600 font-bold"> {product?.discountPrice ? `${product.discountPrice} TL` : ''} </span>
            <span className="text-black dark:text-white font-bold rounded"> {product?.rating || 'N/A'} / 5 <span className="text-yellow-400">★★★★★</span>
            </span>
          </div> 
          
          
          {/* Popülerlik ve Yorum Sayısı */} <p className="text-gray-500 dark:text-white"> 👀 Popüler Ürün! Son 24 saatte{' '} 
          <span className="text-orange-500">{product?.sales || 'Çok Yüksek Sayıda'}</span>
          {' '} kez görüntülendi. </p>

          
          <hr className="my-2" /> 
          
          
          {/* Renk Detayları */} <div>
            <h2 className="text-gray-700 dark:text-white">Renk:</h2>
            <ul className="grid grid-cols-3 md:grid-cols-5 gap-4 items-center overflow-hidden text-white">
              <button type="button" className="transition duration-150 text-white bg-[#23282B] bg-opacity-100 hover:bg-opacity-90 font-medium rounded-sm text-sm px-5 py-2.5">
                <span className="colorbtnvisiblety"> {product?.color1 || 'Bilinmiyor'}</span>
              </button>
              <button type="button" className="transition duration-150 text-white bg-[#f09200] bg-opacity-100 hover:bg-opacity-90 font-medium rounded-sm text-sm px-5 py-2.5">
                <span className="colorbtnvisiblety">{product?.color2 || 'Bilinmiyor'}</span>
              </button>
              <button type="button" className="transition duration-150 text-white bg-[#0086B2] bg-opacity-100 hover:bg-opacity-90 font-medium rounded-sm text-sm px-5 py-2.5">
                <span className="colorbtnvisiblety">{product?.color3 || 'Bilinmiyor'}</span>
              </button>
              <button type="button" className="transition duration-150 text-white bg-[#B81B0E] bg-opacity-100 hover:bg-opacity-90 font-medium rounded-sm text-sm px-5 py-2.5">
                <span className="colorbtnvisiblety">{product?.color4 || 'Bilinmiyor'}</span>
              </button>
              <button type="button" className="transition duration-150 text-white bg-[#A38E78] bg-opacity-100 hover:bg-opacity-90 font-medium rounded-sm text-sm px-5 py-2.5">
                <span className="colorbtnvisiblety">{product?.color5 || 'Bilinmiyor'}</span>
              </button>
            </ul>
          </div> 
          
          

      {/* Satın Alma Butonları */}
      <div>
        <button
          onClick={() => setLightboxOpen(true)} // Lightbox'ı açmak için tıklama olayı eklendi
          className="relative w-full inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-orange-500 dark:text-white hover:text-white dark:hover:text-gray-900 rounded-lg group bg-gradient-to-br from-orange-500 to-orange-500"
        >
          <span className="w-full relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-black rounded-md group-hover:bg-opacity-0 teklifbtn">
            Teklif Al
          </span>
        </button>
      </div>

      {/* Lightbox */}
      {lightboxOpen && (
        <div
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 px-5 pt-10"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <button
              onClick={() => setLightboxOpen(false)}
              className="bg-white bg-opacity-50 rounded-full absolute py-2 px-2 right-8 md:right-20 top-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          <div
            className="rounded px-2 max-w-4xl w-full relative"
            style={{ maxHeight: "90vh", overflowY: "auto" }}
          >
            
            <Quoteform product={product} /> {/* product prop'u eklendi */}
          </div>
        </div>
      )}
          
          
          {/* Kargo Detay */}<div className={`w-full flex justify-left gap-3 p-3 ${product?.cargodelivery < 7 ? 'bg-green-100' : 'bg-slate-200 dark:bg-zinc' } rounded-md text-[#333] dark:text-black font-semibold text-[10px]`}> 🚚 <div> Tahmini Kargoya Teslim: <span className="font-normal">{product?.cargodelivery || 'Bilinmiyor'} Gün içinde.</span>
            </div>
            <div>
              <div>
                <Tooltip message="Belirtilen teslimat tarihi tahmini olup satıcı ve kargo firmasının hizmet seviyelerine 
            ve bölgedeki operasyonel koşullara göre değişiklik gösterebilir.">
                  <button type="button" className="border hover:border-orange-500 hover:text-orange-500 text-[10px] shadow-sm w-4 h-4 rounded-full"> i </button>
                </Tooltip>
              </div>
            </div>
          </div> 
          
{/* Öne Çıkan Özellikler */}
<div>
  <h2 className="text-gray-700 dark:text-white text-sm">Öne Çıkan Özellikler:</h2>
  <ul className="grid grid-cols-3 md:grid-cols-4 gap-2 pt-3 overflow-hidden text-black">
    {[
      { label: 'Ürün Kodu', value: product?.productCode || 'Bilinmiyor' },
      { label: 'Kampanya', value: product?.campaign || 'Yok' },
      { label: 'Ağırlık Ünitesi', value: product?.weight || 'Yok' },
      { label: 'Kullanıcı Yaşı', value: product?.ageRange || 'Yok' },
      { label: 'Çalıştırdığı kas', value: product?.hedefbolge || 'Yok' },
    ].map((item, index) => {
      const { truncated, full } = truncateText(item.value, 15);
      return (
        <li key={index} className="bg-zinc-200 dark:bg-zinc-900 rounded-sm text-gray text-xs lg:text-sm p-4">
          {item.label} <br />
          {full ? ( // Sadece full değeri varsa Tooltip göster
            <Tooltip message={full}>
              <span className="text-black dark:text-white font-semibold">
                {truncated}
              </span>
            </Tooltip>
          ) : (
            <span className="text-black dark:text-white font-semibold">
              {truncated}
            </span>
          )}
        </li>
      );
    })}
  </ul>
</div>


        </div>
      </div>






      <div className="max-w-7xl mx-auto pt-6 px-4 sm:px-6 lg:px-5"> 

        {/* ... Alt kısım ... */}
        <div>

        <div className="pb-6">
            <h2 class="text-gray-700 dark:text-white text-xl">Ürün bilgileri:</h2>
          </div>

          <div className="grid grid-cols-6 gap-4 grid-rows-1 border rounded-lg">
  {/* Sol taraftaki resim */}
  <div className="col-span-6 hidden md:table-cell sm:col-span-2 md:col-span-1 pt-3 px-3 sm:px-0 md:px-0 sm:py-3 md:py-3 sm:pl-3 md:pl-3">
    {product?.images && product.images.length > 0 && (
      <div>
        <img
          src={product.images[0]}
          alt={`${product?.name || 'Resim Bulunamadı'}`}
          className="rounded-lg w-full h-auto object-cover"
          loading="lazy"
        />
      </div>
    )}
  </div>

  {/* Sağ taraftaki içerik */}
  <div className="col-span-6 sm:col-span-6 md:col-span-5 pb-3 px-3 sm:px-3 md:px-0 sm:py-3 md:py-3 sm:pr-3 md:pr-3">
    <h3 className="product-heading3">{product?.name || 'Bilinmiyor'}</h3>
    <ul className="grid grid-cols-2 md:grid-cols-2 gap-2 pt-3 overflow-hidden text-black dark:text-white">
  {[
    { 
      label: 'Ürünün uzunluğu', 
      value: product?.length || null, 
      template: (val) => `Ürünün uzunluğu ${val}’dir.` 
    },
    { 
      label: 'Ürünün genişliği', 
      value: product?.width || null, 
      template: (val) => `Ürünün genişliği ${val}’dir.` 
    },
    { 
      label: 'Ürünün yüksekliği', 
      value: product?.height || null, 
      template: (val) => `Ürünün yüksekliği ${val}’dir.` 
    },
    { 
      label: 'Ürünün ağırlığı', 
      value: product?.equipmentWeight || null, 
      template: (val) => `Ürünün ağırlığı ${val}’dir.` 
    },
    { 
      label: 'Kullanıcı boyu', 
      value: product?.userHeight || null, 
      template: (val) => `Kullanıcı boyu en az ${val} olmalıdır.` 
    },
    { 
      label: 'Güvenli alan', 
      value: product?.safetyArea || null, 
      template: (val) => `Güvenli alan ${val} olarak belirlenmiştir.` 
    },
    { 
      label: 'Montaj detayı', 
      value: product?.baseMounting || null, 
      template: (val) => `Montaj detayı: ${val}.` 
    },
    { 
      label: 'Standart', 
      value: product?.standard || null, 
      template: (val) => `Ürünümüz ${val} standardı gözetilerek üretilmiştir.` 
    },
    { 
      label: 'Üretim ülkesi', 
      value: product?.countryOfManufacture || null, 
      template: (val) => `Ürün ${val} menşeili olarak üretilmiştir.` 
    },
    { 
      label: 'Metal parçalar', 
      value: product?.metalParts || null, 
      template: (val) => `Metal parçalar ${val}ten imal edilmiştir.` 
    },
    { 
      label: 'Hammadde et kalınlığı', 
      value: product?.tubeStrengths || null, 
      template: (val) => `Hammadde et kalınlığı ${val}’dir.` 
    },
  ].map((item, index) => (
    <li
      className="bg-zinc-200 dark:bg-zinc-900 rounded-sm text-black dark:text-white font-semibold text-xs lg:text-sm p-4"
      key={index}
    >
      {item.value
        ? item.template(item.value)
        : `${item.label} bilinmiyor.`}
    </li>
  ))}
</ul>

  </div>
</div>

        </div>

      </div>









    </section>
  );
};

export default ProductDisplay;