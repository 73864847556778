import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { butaksImg, butaksdarkImg, magazadarkImg, magazaImg, aramaImg, aramadarkImg } from '../utils';
import { navLists } from '../constants';
import SunIcon from '../components/sunmoon/SunIcon';
import MoonIcon from '../components/sunmoon/MoonIcon';

const Navbar = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const mobileMenuRef = useRef(null);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setMobileMenuOpen(false);
      }
    };
    if (mobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [mobileMenuOpen]);

  const normalizeText = (text) => {
    if (text === 'ANASAYFA') return '';
    return text
      .replace(/İ/g, 'i')
      .replace(/ı/g, 'i')
      .replace(/Ş/g, 'ş')
      .replace(/ş/g, 's')
      .replace(/Ç/g, 'ç')
      .replace(/ç/g, 'c')
      .replace(/Ğ/g, 'ğ')
      .replace(/ğ/g, 'g')
      .replace(/Ö/g, 'ö')
      .replace(/ö/g, 'o')
      .replace(/Ü/g, 'ü')
      .replace(/ü/g, 'u')
      .toLowerCase()
      .replace(/ /g, '');
  };

  return (
    <header
      className={`headerMain ${scroll 
        ? 'sticky bg-slate-100/80 dark:bg-zinc-900/80 sm:backdrop-blur-md shadow-md'
        : ''} }`}
    >
          <script src="https://www.google.com/recaptcha/api.js" async defer></script>
            <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"/>
            <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap" rel="stylesheet"/>
        <nav className="flex w-11/12 screen-max-width">
        <img
          src={darkMode ? butaksdarkImg : butaksImg} // Dark mode'da farklı logo göster
          alt="BUTAX"
          width={72}
          height={88}
        />
        <ul className="flex flex-1 items-center justify-center max-lg:hidden">
          {navLists.map((nav) => (
            <Link
              key={nav}
              to={`/${normalizeText(nav)}`}
              className="px-5 font-oswald font-medium text-xl cursor-pointer dark:text-white dark:hover:text-red-500 text-black hover:text-gray-700 transition-all"
            >
              {nav}
            </Link>
          ))}
        </ul>
        <div className="flex items-center gap-6 max-lg:justify-end max-lg:flex-1">
          <button className="relative group lg:hidden" onClick={toggleMobileMenu}>
            <div
            class="group flex h-20 w-10 cursor-pointer items-center justify-center rounded-3xl p-2">
          <div class="space-y-2">
            <span class="block h-[3px] w-8 origin-center rounded-full bg-slate-500 transition-transform ease-in-out group-hover:translate-y-1.5 group-hover:rotate-45"></span>
            <span class="block h-[3px] w-8 origin-center rounded-full bg-red-500 transition-transform ease-in-out group-hover:-translate-y-1.5 group-hover:-rotate-45"></span>
          </div>
          </div>
          </button>
          {/*
          <div className="relative flex overflow-hidden items-center justify-center w-[18px] h-[50px] transform transition-all">
            <img
              src={darkMode ? aramadarkImg : aramaImg} // Dark mode'da farklı logo göster
              alt="Arama"
              width={72}
              height={88}
            />
          </div>
          <div className="relative flex overflow-hidden items-center justify-center w-[18px] h-[50px] transform transition-all">
            <img
              src={darkMode ? magazadarkImg : magazaImg} // Dark mode'da farklı logo göster
              alt="Mağaza"
              width={72}
              height={88}
            />
          </div>
          */}
        </div>
        <button className="dm-btn ml-5" onClick={toggleDarkMode}>
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </button>
        {mobileMenuOpen && (
          <div
            ref={mobileMenuRef}
            className={`fixed top-0 left-0 w-full h-full bg-white dark:bg-zinc-900 transform ${
              mobileMenuOpen ? 'translate-x-0' : '-translate-x-full'
            } transition-transform duration-300 ease-in-out sm:hidden z-40`}
          >
            <div className="flex justify-between items-center p-4">
              <img
                src={darkMode ? butaksdarkImg : butaksImg} // Dark mode'da farklı logo göster
                alt="BUTAX"
                width={72}
                height={88}
              />
              <button onClick={toggleMobileMenu} className="text-3xl font-bold text-gray-600 dark:text-white">
                ×
              </button>
            </div>
            <ul className="flex flex-col items-start px-6 space-y-4">
              {navLists.map((nav) => (
                <Link
                  key={nav}
                  to={`/${normalizeText(nav)}`}
                  className="text-xl font-oswald font-medium dark:text-white dark:hover:text-red-500 text-black hover:text-red-500"
                  onClick={toggleMobileMenu}
                >
                  {nav}
                </Link>
              ))}
            </ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;