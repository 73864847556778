// File: butaks-web\src\components\schemas\ProductSchema.jsx
import React from 'react';

const generateProductSchema = (product) => {
  const baseUrl = 'https://www.butaks.com'; // Sitenizin temel URL'si

  return {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": product.name,
    "image": product.images,
    "description": product.description,
    "sku": product.id,
    "brand": {
      "@type": "Brand",
      "name": product.brandName || "Butaks"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": product.rating.toString(),
      "reviewCount": product.sales.replace('+', '')
    },
    "offers": {
    "@type": "Offer",
    "description": "Fiyat bilgisi için lütfen bizimle iletişime geçin.",
    "itemCondition": "https://schema.org/NewCondition",
    "availability": "https://schema.org/InStock",
    "url": `${baseUrl}/urunlerimiz/${product.normalizedCategory}/${product.slug}`
    }
  };
};

const ProductSchema = ({ product }) => {
  const schema = generateProductSchema(product);
  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  );
};

export default ProductSchema;
