import React from 'react'
import { linkedinImg, websiteImg, tubitakImg, atoImg } from '../../utils';

const referanslarimiz = () => {

  return (
    
<div class="my-4">
  <div class="max-w-5xl max-lg:max-w-2xl mx-auto">
    <div class="max-w-2xl mx-auto text-center">
      <h2 class="text-gray-800 text-4xl font-extrabold"></h2>
    </div>
    <div class="grid lg:grid-cols-3 md:grid-cols-2 gap-8 max-md:justify-center mt-12">
      <div class="rounded-md overflow-hidden max-md:max-w-[300px] transform transition duration-500 hover:scale-105">
        <img src={tubitakImg} class="rounded-md w-full h-60 object-contain object-center bg-white" alt='TUBİTAK'/>
        <div class="p-4">
          <h3 class="text-zinc-900 dark:text-white text-base font-bold">TUBİTAK</h3>
          <p class="text-zinc-900 dark:text-white text-xs mt-1">Bilimsel Araştırma Projemiz</p>
          <div class="mt-4">
            <p class="text-zinc-900 dark:text-white text-sm leading-relaxed">Spor ve Rekreasyon Tesislerinin Kullanım Potansiyelini Arttırarak Kentsel Gelişimi Destekleme</p>
          </div>
          <div class="space-x-4 mt-4">
            <button type="button" class="w-7 h-7 inline-flex items-center justify-center rounded-full">
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://tubitak.gov.tr/tr">
                  <img src={websiteImg} alt="Butax Spor ve Rekreasyon Tesislerinin Kullanım Potansiyelini Arttırmak" />
                </a>
              </li>
            </button>
            <button type="button" class="w-7 h-7 inline-flex items-center justify-center rounded-full border-none outline-none bg-[#03a9f4] hover:bg-[#03a1f4] active:bg-[#03a9f4]">
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://tr.linkedin.com/company/tubitak">
                  <img src={linkedinImg} alt="TUBİTAK LinkedIn"/>
                </a>
              </li>
            </button>
          </div>
        </div>
      </div>
      
      <div class="rounded-md overflow-hidden max-md:max-w-[300px] transform transition duration-500 hover:scale-105">
        <img src={atoImg} class="rounded-md w-full h-60 object-contain object-center bg-white" alt='Ankara Ticaret Odası'/>
        <div class="p-4">
          <h3 class="text-zinc-900 dark:text-white text-base font-bold">Ankara Ticaret Odası</h3>
          <p class="text-zinc-900 dark:text-white text-xs mt-1">Üye Olduğumuz Ticaret Odası</p>
          <div class="mt-4">
          </div>
          <div class="space-x-4 mt-4">
            <button type="button" class="w-7 h-7 inline-flex items-center justify-center rounded-full">
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://www.atonet.org.tr">
                  <img src={websiteImg} alt="Butaks ve Ankara Ticaret Odası" />
                </a>
              </li>
            </button>
            <button type="button" class="w-7 h-7 inline-flex items-center justify-center rounded-full border-none outline-none bg-[#03a9f4] hover:bg-[#03a1f4] active:bg-[#03a9f4]">
              <li className="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                <a href="https://tr.linkedin.com/company/tubitak">
                  <img src={linkedinImg} alt="Ankara Ticaret Odası LinkedIn" />
                </a>
              </li>
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

 ) } 

export default referanslarimiz