import React from 'react'
import { squarePlaceholder } from '../../utils';

const ProductSets = () => {

  return (
<section>
  <div>
    <div className='grid text-center sm:grid-cols-1 lg:grid-cols-3 h-96'>
      <div className='overflow-hidden bg-cover bg-center' style={{ backgroundImage: `url(${squarePlaceholder})` }}>
        <div className='p-2 h-full flex items-end justify-center'>
          <h3 className='text-center mb-2 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight'> 6A </h3>
        </div>
      </div>
      <div className='overflow-hidden bg-gradient-to-b from-green-500 to-green-700 content-center'>
        <div className='p-2 h-full flex items-center justify-center'>
          <h3 className='text-center mb-2 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight'> 8AB </h3>
          <span className='text-left mb-2 text-md text-black dark:text-white font-bold leading-tight'>Ürün setimiz üretim aşamasındadır. Anlayışınız için teşekkür ederiz!</span>
        </div>
      </div>
      <div className='overflow-hidden bg-gradient-to-b from-sky-400 to-sky-600 content-center'>
        <div className='p-2 h-full flex items-center justify-center'>
          <h3 className='text-center mb-2 text-3xl md:text-5xl text-black dark:text-white font-bold leading-tight'> 12CD </h3>
          <span className='text-left mb-2 text-md text-black dark:text-white font-bold leading-tight'> Ürün setimiz üretim aşamasındadır. Anlayışınız için teşekkür ederiz!</span>
        </div>
      </div>
    </div>
  </div>
</section>

) } 

export default ProductSets