import React from 'react'
import { spordayapayzekaImg } from '../../utils';

const Ozellikbes = () => {

  return (
    <section className="nocommon-padding">
      <div className="">
        <div className="w-full">
          <h3 className="text-lg md:text-2xl font-poppins uppercase text-black font-bold leading-tight">Yapay Zeka Destekli</h3>
          <h2 className="text-2xl md:text-5xl font-oswald uppercase text-black font-thin leading-tight pb-10">Bu yapay zeka napay?</h2>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">Yapay zeka teknolojisi ile desteklenen BUTAfit uygulaması, kişiselleştirilmiş antrenman programları ve hedef takibi ile size daha etkili bir spor deneyimi sunar.</p>
          </div>
          <div class="flex-item-right">
          <img class="w-full rounded-xl" src={spordayapayzekaImg} alt="Yapay Zeka ile Spor"/>
          </div>
        </div>
        <div className="w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5 font-bold">
          <p class="text-zinc-800 font-poppins text-lg md:text-2xl">BUTAfit uygulaması, yapay zeka teknolojisi ile kişiselleştirilmiş antrenman programları oluşturur ve size motivasyon sağlar.</p>
        </div>
        <div class="flex-container-iki w-full bg-slate-50 py-4 px-4 rounded-[30px] my-5">
          <div class="flex-item-left">
            <p class="text-zinc-800 font-poppins text-lg md:text-2xl font-bold pr-4 pb-4">İleri teknoloji ile desteklenen BUTAfit uygulaması, size kişiselleştirilmiş antrenman önerileri sunar ve hedeflerinize ulaşmanızda size yardımcı olur.</p>
          </div>
          <div class="flex-item-right"></div>
        </div>
      </div>
    </section>

  );
} 

export default Ozellikbes;