import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useGSAP } from '@gsap/react';
import { butakstanitimVideo, butaksmobiletanitimVideo } from '../../utils';
import { useEffect, useState } from 'react';
import VideoObjectSchema from '../schemas/VideoObjectSchema';
import { videoData } from '../../datas/videoData';

gsap.registerPlugin(ScrollTrigger);
const isMobile = window.matchMedia('(max-width: 768px)').matches;

const Hero = () => {
  const [videoSrc, setVideoSrc] = useState(window.innerWidth < 760 ? butaksmobiletanitimVideo : butakstanitimVideo);

  const handleVideoSrcSet = () => {
    setVideoSrc(window.innerWidth < 760 ? butaksmobiletanitimVideo : butakstanitimVideo);
  };

  useEffect(() => {
    window.addEventListener('resize', handleVideoSrcSet);
    return () => window.removeEventListener('resize', handleVideoSrcSet);
  }, []);

  useGSAP(() => {
    if (!isMobile) {
      gsap.fromTo('.welcome-video-video', { width: '100%', borderRadius: 0 }, {
        width: '85%',
        borderRadius: '80px',
        scrollTrigger: {
          trigger: '.welcome-video-video',
          start: 'top 50%',
          end: 'bottom 10%',
          scrub: true,
        },
      });
    } else {
      gsap.set('.welcome-video-video', { width: '90%', borderRadius: '20px' });
    }
  }, [isMobile]);

  return (
    <section className="w-full bg-white dark:bg-black relative">
      <div className="flex-container items-center py-10">
        <h1 className="hero-title flex-item-left">BUTAX</h1>
        <p className="heroalt-title flex-item-right">Üst Düzey Spor Alanlarının Kurucusu.</p>
      </div>
      <div className="flex-col pb-10">
        <div className="w-full flex-center">
          <video className="welcome-video-video" autoPlay muted playsInline={true} key={videoSrc}>
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </div>
    </section>
  );
};

export default Hero;