import React from 'react';
import { footerLinks } from '../constants';  // Varsayılan linkler burada
import { Link } from 'react-router-dom';

const normalizeText = (text) => {
  if (text === 'Anasayfa') return '';
  return text
    .replace(/İ/g, 'i')
    .replace(/ı/g, 'i')
    .replace(/Ş/g, 'ş')
    .replace(/ş/g, 's')
    .replace(/Ç/g, 'ç')
    .replace(/ç/g, 'c')
    .replace(/Ğ/g, 'ğ')
    .replace(/ğ/g, 'g')
    .replace(/Ö/g, 'ö')
    .replace(/ö/g, 'o')
    .replace(/Ü/g, 'ü')
    .replace(/ü/g, 'u')
    .toLowerCase()
    .replace(/ /g, '-'); // Boşlukları tire ile değiştir
};

const Footer = () => {
  return (
    <footer className="py-5 sm:px-10 px-5 bg-white dark:bg-black w-full mt-auto">
      <div className="screen-max-width mx-auto">
        <div>
          <p className="font-semibold text-zinc-900 dark:text-gray text-xs">
            Daha fazla bilgi için: {' '}
            <span className="underline text-blue cursor-pointer">
              Butaks Üretim Tesisi {' '}
            </span>
            ya da size en yakın {' '}
            <span className="underline text-blue cursor-pointer">
              Mağazamızı
            </span>{' '}
            ziyaret ediniz.
          </p>
          <p className="font-semibold text-zinc-900 dark:text-gray text-xs">
            Veya +90-530-660-20-24 numaralı telefonu arayın.
          </p>
        </div>

        <div className="bg-neutral-700 my-5 h-[1px] w-full" />

        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-semibold text-zinc-900 dark:text-gray text-xs">
            Telif Hakkı © 2024 Butaks Lim. Tüm hakları saklıdır.
          </p>
          <ul className="flex flex-wrap">
          {footerLinks.map((link, i) => (
            <li key={link} className="font-semibold text-zinc-900 dark:text-gray text-xs cursor-pointer">
              <Link 
                to={`/${normalizeText(link)}`} // normalizeText kullanıldı 
                className="text-zinc-900 dark:text-gray"
              >
                {link}{' '}
              </Link>
              {i !== footerLinks.length - 1 && (
                <span className="mx-2 cursor-default"> | </span>
              )}
            </li>
          ))}
        </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
