import React from 'react';


const Termsofuse = () => {
  return (
    <section className="p-5 md:p-10">
 <div className="text-center my-10">
  <h1 className="text-3xl md:text-5xl font-semibold text-zinc-900 dark:text-white"> Butaks Website Kullanım Politikası </h1>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg'>13 Eylül 2024 tarihinde güncellendi</p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg'>Yasal Bilgiler & Bildirimler</p>
  <p className="text-zinc-900 dark:text-white text-base md:text-lg"> Butaks web sitesi kullanım politikasının amacı, <span className="font-bold"> web sitesini kullanırken uyulması gereken kuralları ve koşulları belirlemektir.</span> Bu, hem Butaks'ı hem de web sitesini kullananları korumak için gereklidir. </p>
 </div>
 <div className="md:px-48 mt-5">
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Site Mülkiyeti; Kullanım Koşullarını Kabul Etme </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Kullanım Koşulları ("Kullanım Koşulları"), www.butaks.com adresinde bulunan Butaks web sitesi ve Butaks, taraf şirketleri ve iştirakleri tarafından www.butaks.com'a bağlantılı tüm ilişkili siteler (dünya çapındaki Butaks siteleri dahil) (topluca "Site" olarak anılacaktır) için geçerlidir. Site, Butaks'ın mülkiyetindedir ve lisans verenleri tarafından yönetilir. SİTEYİ KULLANARAK, BU KULLANIM KOŞULLARINI KABUL EDERSİNİZ; KABUL ETMİYORSANIZ, SİTEYİ KULLANMAYIN. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, kendi takdirine bağlı olarak, bu Kullanım Koşullarının herhangi bir bölümünü dilediği zaman değiştirebilir, düzenleyebilir, ekleyebilir veya çıkarabilir. Bu Kullanım Koşullarında yapılan değişiklikleri düzenli olarak kontrol etme sorumluluğu size aittir. Değişiklikler yayınlandıktan sonra Siteyi kullanmaya devam etmeniz, değişiklikleri kabul ettiğiniz ve onayladığınız anlamına gelir. Bu Kullanım Koşullarına uyduğunuz sürece Butaks size Siteye giriş yapma ve Siteyi kullanma konusunda kişisel, münhasır olmayan, devredilemez ve sınırlı bir ayrıcalık verir. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> İçerik </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'de bulunan tüm metin, grafik, kullanıcı arayüzü, görsel arayüz, fotoğraf, ticari marka, logo, ses, müzik, sanat eseri ve bilgisayar kodu (topluca "İçerik"), tasarım, yapı, seçim, koordinasyon, ifade, "görünüm ve his", bu İçeriğin düzenlenmesi de dahil olmak üzere, ancak bunlarla sınırlı olmamak üzere, Butaks tarafından veya Butaks'a lisanslanmıştır ve ticari görünüm, telif hakkı, patent ve ticari marka yasaları ve çeşitli diğer fikri mülkiyet hakları ve haksız rekabet yasalarıyla korunmaktadır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Kullanım Koşullarında açıkça belirtilmedikçe, Site'nin hiçbir kısmı ve İçerik'in hiçbir kısmı, Butaks'ın önceden yazılı izni olmadan, yayın veya dağıtım için veya herhangi bir ticari girişim için, başka bir bilgisayara, sunucuya, web sitesine veya başka bir ortama herhangi bir şekilde ( "aynalama" dahil) kopyalanamaz, çoğaltılamaz, yeniden yayınlanamaz, yüklenemez, yayınlanamaz, kamuya açıklanamaz, kodlanamaz, çevrilemez, iletilemez veya dağıtılamaz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'den indirilmek üzere Butaks tarafından özel olarak sağlanan Butaks ürün ve hizmetleri hakkında bilgi (veri sayfaları, bilgi bankası makaleleri ve benzeri materyaller gibi) kullanabilirsiniz, ancak (1) bu belgelerin tüm kopyalarında mülkiyet bildirimi dilini kaldırmamanız, (2) bu bilgileri yalnızca kişisel, ticari olmayan bilgilendirme amacınız için kullanmanız ve bu bilgileri herhangi bir ağa bağlı bilgisayara kopyalamamanız veya herhangi bir medyada yayınlamamanız, (3) bu bilgilerde herhangi bir değişiklik yapmamanız ve (4) bu belgelerle ilgili ek beyan veya garanti vermemeniz şartıyla. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Sitenin Kullanımı </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'nin veya İçerik'in herhangi bir bölümüne erişmek, elde etmek, kopyalamak veya izlemek için veya Site'nin veya İçerik'in gezinme yapısı veya sunumunu yeniden üretmek veya devre dışı bırakmak için veya Site aracılığıyla özel olarak sağlanmayan herhangi bir yolla materyaller, belgeler veya bilgiler elde etmek veya elde etmeye çalışmak için "derin bağlantı", "sayfa kazıma", "robot", "örümcek" veya başka bir otomatik cihaz, program, algoritma veya metodoloji veya benzeri veya eşdeğer manuel bir işlem kullanamazsınız. Butaks bu tür her türlü faaliyeti engelleme hakkını saklı tutar. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'nin veya Site'ye bağlı herhangi bir sistem veya ağa veya Butaks sunucusuna veya Site'de veya Site aracılığıyla sunulan hizmetlerden herhangi birine, hackleme, şifre "madenciliği" veya başka bir yasadışı yöntemle yetkisiz erişim sağlamaya çalışamazsınız. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'nin veya Site'ye bağlı herhangi bir ağın güvenlik açıklarını araştıramaz, tarayamaz veya test edemezsiniz ve Site'nin veya Site'ye bağlı herhangi bir ağın güvenlik veya kimlik doğrulama önlemlerini ihlal edemezsiniz. Site'nin veya Site'ye giren başka bir kullanıcının veya ziyaretçinin veya Butaks'ın başka bir müşterisinin, size ait olmayan herhangi bir Butaks hesabı dahil, kaynağına kadar geriye doğru arama, izleme veya izleme girişiminde bulunamaz veya Siteyi veya Site'de veya Site aracılığıyla sağlanan veya sunulan herhangi bir hizmeti veya bilgiyi, amaç olarak kendi bilgileriniz dışında, Site tarafından sağlanan bilgiler dahil, ancak bunlarla sınırlı olmamak üzere, kişisel tanımlama bilgileri veya bilgiler de dahil olmak üzere, herhangi bir bilgiyi ortaya çıkarmak için kullanamazsınız. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'nin altyapısına veya Butaks'ın sistemlerine veya ağlarına veya Site'ye veya Butaks'a bağlı herhangi bir sistem veya ağa makul olmayan veya orantısız büyük bir yük bindiren herhangi bir işlemde bulunmayacağınızı kabul edersiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'nin veya Site'de yürütülen herhangi bir işlemin veya başka bir kişinin Site'yi kullanmasının düzgün çalışmasını engellemek veya engellemeye çalışmak için herhangi bir cihaz, yazılım veya rutin kullanamayacağınızı kabul edersiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'de veya Site aracılığıyla veya Site'de veya Site aracılığıyla sunulan herhangi bir hizmet aracılığıyla Butaks'a gönderdiğiniz herhangi bir mesajın veya iletimizin kaynağını gizlemek için başlıkları sahte olarak oluşturamaz veya tanımlayıcıları başka şekilde değiştiremezsiniz. Başka biri olduğunuzu veya başka birini temsil ettiğinizi iddia edemez veya başka bir bireyi veya kuruluşu taklit edemezsiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Siteyi veya herhangi bir İçeriği yasadışı veya bu Kullanım Koşulları tarafından yasaklanmış herhangi bir amaç için veya herhangi bir yasadışı eylemin veya Butaks'ın veya başkalarının haklarını ihlal eden başka bir eylemin gerçekleştirilmesini talep etmek için kullanamazsınız. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Satın Alımlar; Diğer Koşullar ve Şartlar </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Mal veya hizmet satın alımları ve Site'nin belirli bölümleri veya özellikleri, yarışmalar, promosyonlar veya diğer benzer özellikler dahil, tümünün bu referans yoluyla bu Kullanım Koşullarının bir parçası haline getirildiği ek koşullar ve şartlar geçerli olabilir. Bu tür diğer koşullar ve şartlara, geçerli olduğu yerlerde, bu tür hizmet veya özelliği kullanmak veya bunlara katılmak için yasal olarak yeterli yaşta olduğunuzu beyan etmek de dahil olmak üzere, uymayı kabul edersiniz. Bu Kullanım Koşulları ile Site'nin belirli bir bölümü için veya Site'de veya Site aracılığıyla sunulan herhangi bir hizmet için yayınlanan veya uygulanan koşullar arasında bir çakışma olması durumunda, Site'nin o bölümünün veya belirli hizmetin kullanımıyla ilgili olarak sonuncu koşullar geçerli olacaktır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks'ın ürün ve hizmetlerine ilişkin yükümlülükleri, varsa, yalnızca bunların sağlandığı sözleşmeler tarafından yönetilir ve Site'deki hiçbir şey bu sözleşmeleri değiştirmek için yorumlanmamalıdır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, Site'de sunulan ürün veya hizmetlerde veya bu tür ürün veya hizmetlere uygulanan fiyatlarda, bildirimde bulunmaksızın, herhangi bir zamanda değişiklik yapabilir. Ürün ve hizmetlere ilişkin Site'deki materyaller güncel olmayabilir ve Butaks, bu tür ürün ve hizmetlere ilişkin Site'deki materyalleri güncelleme konusunda herhangi bir taahhütte bulunmaz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Aşağıdaki koşullar ayrıca Site'nin kullanımınızı yönetir ve uygular ve bu referans yoluyla buraya dahil edilir: </p>
  <ul className="list-none pl-5 py-1">
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Butaks Ticari Markalarını ve Telif Haklarını Kullanma Kılavuzları</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Haklar ve İzinler</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Ticari Markalar</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Telif Hakkı İhlali İddiaları</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Telif Hakkı İhlali</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Sahte Ürünler</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Butaks'ın İstenmeyen Fikir Gönderim Politikası</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Yazılım Lisansı Bilgileri</span>
   </li>
   <li className="text-zinc-900 dark:text-white text-base md:text-lg py-1">
    <span className="font-bold">Yasal İletişim</span>
   </li>
  </ul>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu politikaların her biri zaman zaman değiştirilebilir ve Site'ye bu değişiklikleri yayınladığımız anda hemen yürürlüğe girer. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Hesaplar, Şifreler ve Güvenlik </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'de veya Site aracılığıyla sunulan belirli özellikler veya hizmetler, hesap açmanızı (Butaks Kimliği ve şifre ayarlama dahil) gerektirebilir. Hesabınız için, şifreniz dahil, sakladığınız bilgilerin gizliliğini ve hesabınızda, bu bilgileri güvenli ve gizli tutmamanız sonucu meydana gelen tüm faaliyetlerden sorumlu olduğunuzdan tamamen siz sorumlusunuz. Hesabınızın veya şifrenizin yetkisiz kullanımını veya güvenliğin başka herhangi bir ihlalini derhal Butaks'a bildirmeyi kabul edersiniz. Hesap bilgilerinizi güvenli ve gizli tutmamanız sonucu bir başkasının Butaks Kimliğinizi, şifrenizi veya hesabınızı kullanması nedeniyle Butaks'ın veya Site'nin başka bir kullanıcısının veya ziyaretçisinin uğradığı kayıplardan sorumlu tutulabilirsiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks Kimliği, şifre veya hesabın sahibinin açık izni ve onayı olmadan hiçbir zaman başka birinin Butaks Kimliğini, şifresini veya hesabını kullanamazsınız. Butaks, bu yükümlülüklere uymamanızdan kaynaklanan herhangi bir kayıp veya zarardan sorumlu olamaz ve olmayacaktır. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Gizlilik </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks'ın Gizlilik Politikası, bu Site'nin kullanımını kapsar ve koşulları, bu referans yoluyla bu Kullanım Koşullarının bir parçası haline getirilir. Butaks'ın Gizlilik Politikasını görüntülemek için buraya tıklayın. Ek olarak, Siteyi kullanarak, İnternet iletilerinin asla tamamen gizli veya güvenli olmadığını kabul edersiniz ve onaylarsınız. Site'ye gönderdiğiniz herhangi bir mesajın veya bilginin, belirli bir iletimde (örneğin, kredi kartı bilgileri) özel bir bildirimde şifrelenmiş olsa bile, başkaları tarafından okunabileceğini veya kesilebileceğini anlıyorsunuz. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Diğer Sitelere ve Butaks Sitesine Bağlantılar </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Site, bağımsız üçüncü taraf web sitelerine ("Bağlantılı Siteler") bağlantılar içerebilir. Bu Bağlantılı Siteler, ziyaretçilerimize yalnızca kolaylık sağlamak amacıyla sunulur. Bu Bağlantılı Siteler Butaks'ın kontrolünde değildir ve Butaks bu Bağlantılı Sitelerin içeriğinden, bu Bağlantılı Sitelerde yer alan herhangi bir bilgi veya materyalden sorumlu değildir ve bunları onaylamaz. Bu Bağlantılı Sitelerle etkileşiminizle ilgili kendi bağımsız yargınızı yapmanız gerekecektir. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Sorumluluk Reddi </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> BUTAKS, SİTE'NİN VEYA SİTE'NİN HERHANGİ BİR İÇERİĞİNİN, HİZMETİNİN VEYA ÖZELLİĞİNİN HATASIZ OLACAĞINI VEYA KESİNTİSİZ ÇALIŞACAĞINI, HERHANGİ BİR ARIZANIN DÜZELTİLECEĞİNİ VEYA SİTEYİ KULLANMANIZIN ÖZEL SONUÇLAR SAĞLAYACAĞINI VAAT ETMEZ. SİTE VE İÇERİĞİ "OLDUĞU GİBİ" VE "KULLANILABİLİR DURUMDA" TEMİN EDİLİR. SİTE'DE VERİLEN TÜM BİLGİLER BİLDİRİMSİZ OLARAK DEĞİŞTİRİLEBİLİR. BUTAKS, SİTE'DEN İNDİRDİĞİNİZ HERHANGİ BİR DOSYANIN VEYA DİĞER VERİLERİN VİRÜS, KİRLENME VEYA YIKICI ÖZELLİKLERDEN ARINIK OLACAĞINI GARANTİ EDEMEZ. BUTAKS, DOĞRULUK, TİCARİ DEĞER, TEK BİR AMAÇ İÇİN UYGUNLUK İHLALİ, TİCARİ DEĞER, BELİRLİ BİR AMAÇ İÇİN UYGUNLUK İHLALİ DÂHİL, AÇIK VEYA ZİHNEN HERHANGİ BİR GARANTİYİ REDDEDER. BUTAKS, SİTE'NİN KULLANIMINIZLA VE/VEYA HERHANGİ BİR BUTAKS HİZMETİYLE İLGİLİ OLARAK VEYA BUNUNLA İLGİLİ OLARAK ÜÇÜNCÜ ŞAHSIN EYLEMLERİ, KUSURLARI VE DAVRANIŞLARI İÇİN HERHANGİ BİR SORUMLULUKTAN YAZILI OLARAK FERAGAT EDER. SİTEYİ VE HERHANGİ BİR BAĞLANTILI SİTEYİ KULLANMANIN TAM SORUMLULUĞU SİZE AİTTİR. SİTEYLE VEYA HERHANGİ BİR İÇERİKLE İLGİLİ OLMAYAN HERHANGİ BİR ŞEYDEN DOLAYI BUTAKS'A KARŞI TEK ÇARE SİTEYİ VEYA HERHANGİ BİR BÖYLE İÇERİĞİ KULLANMAYI DURDURMAKTIR. BU YARDIM SINIRLAMASI, TARAFLAR ARASINDAKİ ANLAŞMANIN BİR PARÇASIDIR. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Yukarıdaki sorumluluk reddi, performansın başarısızlığı, hata, eksiklik, kesinti, silme, kusur, işlem veya iletimde gecikme, bilgisayar virüsü, iletişim hattı arızası, hırsızlık veya imha veya yetkisiz erişim, değiştirme veya kullanım nedeniyle meydana gelen veya ilgili olan herhangi bir zarardan, yükümlülükten veya yaralanmadan dolayı, sözleşme ihlali, haksız fiil, ihmal veya herhangi bir diğer davadan kaynaklanan veya ilgili olan herhangi bir zarardan, yükümlülükten veya yaralanmadan dolayı geçerlidir. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, bildirimde bulunmaksızın, herhangi bir zamanda aşağıdakileri yapabilir: (1) herhangi bir nedenle Site'nin veya Site'nin herhangi bir bölümünün işlemini veya erişimini değiştirmek, askıya almak veya sonlandırmak; (2) Site'yi veya Site'nin herhangi bir bölümünü ve geçerli herhangi bir politikayı veya koşulu değiştirmek veya değiştirmek; ve (3) rutini veya rutinden olmayan bakım, hata düzeltmesi veya diğer değişiklikler yapmak için gerektiğinde Site'nin veya Site'nin herhangi bir bölümünün işlemini kesintiye uğratmak. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Sorumluluk Sınırı </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Yasa tarafından yasaklanmadığı müddetçe, hiçbir durumda Butaks, kar kaybı dahil, dolaylı, sonuç olarak ortaya çıkan, örnek teşkil eden, tali veya cezai zararlardan dolayı size sorumlu tutulamaz, Butaks bu tür zararların olasılığı konusunda bilgilendirilmiş olsa bile. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Kullanım Koşullarının diğer hükümlerine rağmen, Butaks'ın Site'yi veya herhangi bir İçerik'i kullanmanızdan kaynaklanan veya herhangi bir şekilde kullanımınızla bağlantılı olan herhangi bir zarar veya kayıptan dolayı size sorumlu tutulduğu tespit edilirse, Butaks'ın yükümlülüğü hiçbir durumda (1) Site'nin herhangi bir hizmet veya özelliğiyle ilgili abonelik veya benzeri ücretlerin, Butaks'a karşı ilk talep tarihinden önceki altı ayda ödenen toplamından (ancak herhangi bir Butaks donanım veya yazılım ürününün satın alma fiyatını veya herhangi bir AppleCare veya benzeri destek programını dahil etmeksizin) veya (2) 100,00 ABD Dolarından fazla olmayacaktır. Bazı yargı bölgeleri yükümlülük sınırlamalarına izin vermez, bu nedenle yukarıdaki sınırlama sizin için geçerli olmayabilir. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Tazminat </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Siz, Butaks'ı, yöneticilerini, yöneticilerini, pay sahiplerini, öncüllerini, iştirakçilerini, çalışanlarını, temsilcilerini, taraf şirketlerini ve iştiraklerini, Siteyi kullanımınızdan kaynaklanan veya Site ile bağlantılı olan veya bununla ilgili olan herhangi bir talebe, zarara, yükümlülüğe, iddiaya veya giderden (avukat ücretleri dahil), Butaks'a karşı üçüncü bir taraf tarafından yapılması halinde zararsız kılmayı kabul edersiniz. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Bu Kullanım Koşullarının İhlali </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, Site'nin kullanımınızla ilgili herhangi bir soruşturma veya şikayet bağlamında, kimliğiniz dahil, sizin hakkınızda bulunduğumuz herhangi bir bilgiyi ifşa edebilir veya Site'yi zarara uğratan veya (kasıtlı veya kasıtsız) Butaks'ın haklarını veya mülkiyetini veya Site'nin ziyaretçilerinin veya kullanıcılarının, Butaks'ın müşterileri dahil haklarını veya mülkiyetini engellemekle ilgili herhangi bir soruşturma veya şikayet bağlamında, kimliğiniz dahil, sizin hakkınızda bulunduğumuz herhangi bir bilgiyi ifşa edebilir. Butaks, uygulanabilir herhangi bir yasa, düzenleme, yasal işlem veya hükümet talebine uymak için gerekli gördüğü herhangi bir bilgiyi her zaman ifşa etme hakkını saklı tutar. Ayrıca, Butaks, uygulanabilir yasaların ifşa etmeyi gerektirdiği veya izin verdiği durumlarda, dolandırıcılık koruma amaçları dahil olmak üzere diğer şirketler ve organizasyonlarla bilgi değişimi dahil, bilgilerinizi de ifşa edebiliriz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Siz, Butaks'ın Site aracılığıyla veya Site'de veya Site aracılığıyla sunulan herhangi bir hizmet aracılığıyla Butaks ile aracılığıyla yaptığınız herhangi bir iletimi veya iletişimi saklayabileceğini ve bu verileri (1) yasal işlemi uygulamak, (2) bu Kullanım Koşullarını uygulamak, (3) bu tür verilerin başkalarının haklarını ihlal ettiği iddialarına yanıt vermek veya (4) Butaks'ın, çalışanlarının, Site'nin kullanıcılarının veya ziyaretçilerinin ve kamuoyunun haklarını, mülkiyetini veya kişisel güvenliğini korumak için makul olarak gerekli olduğuna karar verirse, ifşa etmesi gerekiyorsa, ifşa edebileceğini kabul edersiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, kendi takdirine bağlı olarak ve önceden bildirimde bulunmaksızın, bu Kullanım Koşullarını veya Site'nin kullanımınızla ilgili olabilecek diğer sözleşmeleri veya kılavuzları ihlal ettiğinizi belirlersek, Site'ye erişiminizi sonlandırabilir veya Site'ye gelecekteki erişiminizi engellemeyi kabul edersiniz. Ayrıca, bu Kullanım Koşullarını ihlal etmeniz, yasadışı ve haksız ticari uygulama teşkil edecek ve Butaks'a parasal tazminatın yetersiz olacağı telafi edilemez bir zarar vererek, bu gibi durumlarda Butaks'ın gerekli veya uygun gördüğü herhangi bir yasaklayıcı veya adil tazminatı elde etmenize rıza gösterirsiniz. Bu tazminatlar, Butaks'ın kanunda veya adil yargıda sahip olabileceği diğer tazminatlara ektir. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, kendi takdirine bağlı olarak ve önceden bildirimde bulunmaksızın, (1) kanun uygulama veya diğer hükümet kurumlarının talepleri, (2) sizin bir talebi (kendi başınıza başlatılan hesap silmeleri), (3) Site'nin veya Site'de veya Site aracılığıyla sunulan herhangi bir hizmetin sonlandırılması veya önemli şekilde değiştirilmesi veya (4) beklenmedik teknik sorunlar veya problemler dahil olmak üzere, geçerli bir nedenle Site'ye erişiminizi sonlandırabilir. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Kullanım Koşullarını ihlal etmeniz sonucu Butaks sizinle ilgili herhangi bir hukuki işlem başlatırsa, Butaks sizin tarafınızdan tazmin edilmeye hak kazanır ve siz, Butaks'a verilen diğer her türlü tazminata ek olarak, bu işlemin tüm makul avukat ücretlerini ve masraflarını ödemeyi kabul edersiniz. Bu Kullanım Koşullarını ihlal etmeniz sonucu Site'ye erişiminizin sonlandırılması nedeniyle Butaks'ın size veya üçüncü bir tarafa karşı yükümlülüğü olmayacağını kabul edersiniz. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Yürürlükte Olan Kanun; Anlaşmazlıkların Çözümü </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'ye erişiminizle veya Site'yi kullanımınızla ilgili tüm konularda, tüm anlaşmazlıklar dahil, çatışan yasalar hükümleri göz önünde bulundurulmaksızın, Amerika Birleşik Devletleri yasaları ve Kaliforniya Eyaleti yasaları geçerli olacaktır. Santa Clara County, Kaliforniya'daki eyalet ve federal mahkemelerin kişisel yargı yetkisini kabul edersiniz ve bu tür yargı yetkisine veya yerine karşı herhangi bir itirazdan vazgeçersiniz. Yer ile ilgili önceki hüküm, Avrupa Birliği'nde ikamet eden bir tüketici isenseniz geçerli değildir. Avrupa Birliği'nde ikamet eden bir tüketici isenseniz, ikamet ettiğiniz ülkenin mahke</p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Yürürlükte Olan Kanun; Anlaşmazlıkların Çözümü </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Site'ye erişiminizle veya Site'yi kullanımınızla ilgili tüm konularda, tüm anlaşmazlıklar dahil, çatışan yasalar hükümleri göz önünde bulundurulmaksızın, Amerika Birleşik Devletleri yasaları ve Kaliforniya Eyaleti yasaları geçerli olacaktır. Santa Clara County, Kaliforniya'daki eyalet ve federal mahkemelerin kişisel yargı yetkisini kabul edersiniz ve bu tür yargı yetkisine veya yerine karşı herhangi bir itirazdan vazgeçersiniz. Yer ile ilgili önceki hüküm, Avrupa Birliği'nde ikamet eden bir tüketici isenseniz geçerli değildir. Avrupa Birliği'nde ikamet eden bir tüketici isenseniz, ikamet ettiğiniz ülkenin mahkemelerinde bir davada bulunabilirsiniz. Bu Kullanım Koşulları kapsamındaki herhangi bir iddia, iddia nedeninin ortaya çıktığı tarihten itibaren bir (1) yıl içinde açılmalıdır, aksi takdirde bu iddia veya iddia nedeni ortaya çıkar. Mal ve hizmet satın alma koşullarında belirtilen ayrı koşul ve şartlar altında açılan iddialar bu sınırlamaya tabi değildir. Geri ödeme harcamalarından başka zarar için tazminat talep edilemez veya alınamaz, ancak davayı kazanan taraf masraflarını ve avukat ücretlerini talep edebilir. Site'yi kullanımınızdan kaynaklanan veya kullanımınızla ilgili olarak ortaya çıkan Butaks ile siz arasında herhangi bir tartışma veya anlaşmazlık olması durumunda, taraflar bu tür herhangi bir anlaşmazlığı derhal ve iyi niyetle çözmeye çalışacaklardır. Bu tür herhangi bir anlaşmazlığı makul bir zaman içinde (otuz (30) günden fazla olmamak üzere) çözemezsek, tarafların her biri bu tür anlaşmazlığı veya tartışmayı arabuluculuğa sunabilir. Anlaşmazlık arabuluculuk yoluyla çözülemezse, tarafların uygulanabilir yasalar kapsamında kendilerine açık olan herhangi bir hakkı veya tazminatı kovalama özgürlüğü olur. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Yasalara Aykırı Olduğu Yerlerde Geçersizdir </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, www.butaks.com Sitesini Cupertino, Kaliforniya, ABD'deki konumundan yönetir ve işlettirir; diğer Butaks siteleri, ABD dışındaki çeşitli konumlardan yönetilebilir ve işletilir. Site dünya çapında erişilebilir olsa da, Site'de tartışılan, bahsedilen, sağlanan veya sunulan tüm özellikler, ürün veya hizmetler tüm kişilere veya tüm coğrafi konumlarda erişilebilir veya uygun değildir veya ABD dışında kullanım için uygun veya erişilebilir değildir. Butaks, kendi takdirine bağlı olarak, herhangi bir kişiye veya coğrafi bölgeye herhangi bir özelliğin, ürünün veya hizmetin sağlanmasını ve miktarını sınırlama hakkını saklı tutar. Site'de yapılan herhangi bir özellik, ürün veya hizmet teklifi, yasaklandığı yerlerde geçersizdir. Site'ye ABD dışından erişmeyi seçerseniz, bunu kendi inisiyatifiniz üzerine yaparsınız ve geçerli yerel yasalara uymanın yalnızca siz sorumlusunuz. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Çeşitli Hükümler </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Uygulanabilir yasalara veya düzenlemelere (ABD'nin ihracat yasaları ve düzenlemeleri dahil olmak üzere, ancak bunlarla sınırlı olmamak üzere) aykırı olarak, İçerik'i veya İçerik'in herhangi bir kopyasını veya uyarlamasını veya Site'de sunulan herhangi bir ürünü veya hizmeti kullanamaz, ihraç edemez veya tekrar ihraç edemezsiniz. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu Kullanım Koşullarının hükümlerinden herhangi biri, yetkili bir mahkeme veya diğer bir yargı kurulu tarafından geçersiz veya uygulanamaz olarak belirlenir ise, bu hükümler gerektiği kadar sınırlı hale getirilecek veya kaldırılacak ve bu Kullanım Koşullarının amacını en iyi şekilde yansıtan geçerli bir hükümle yer değiştirilecek, böylece bu Kullanım Koşulları tüm güç ve etkisiyle devam edecektir. Bu Kullanım Koşulları, Site'nin kullanımınızla ilgili olarak siz ve Butaks arasında var olan tüm anlaşmayı temsil eder ve bu kullanımla ilgili olarak daha önce sizin ve Butaks arasında var olan tüm diğer yazılı veya sözlü anlaşmalar veya anlayışlar, bu yolla feshedilmiş ve iptal edilmiştir. Butaks ile yaptığınız bir satın alma sözleşmesinde belirtilmediği sürece, Butaks, bu Kullanım Koşullarına karşı teklif kabul etmez ve bu tür tüm teklifler bu yolla açıkça reddedilir. Butaks'ın, bu Kullanım Koşullarına uygun katı performansa ısrar etmemesi veya bunu zorlamaması, Butaks tarafından bu Kullanım Koşullarındaki herhangi bir hükmün veya Butaks'ın bu Kullanım Koşullarını uygulama hakkının feragatnamesi olarak yorumlanmamalıdır ve sizinle veya başka herhangi bir taraf arasında uygulanan hiçbir davranış şekli, bu Kullanım Koşullarının herhangi bir hükmünü değiştirmek olarak değerlendirilmemelidir. Bu Kullanım Koşulları, üçüncü tarafların herhangi bir hakkı veya tazminat sağlamak olarak yorumlanmamalıdır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks, uluslararası verilerine erişim sağlar ve bu nedenle, ülkenizde duyurulmamış olan Butaks ürünleri, programları ve hizmetleri hakkında referanslar veya çapraz referanslar içerebilir. Bu referans, ülkenizdeki Butaks'ın bu tür ürünleri, programları veya hizmetleri duyurmayı planladığı anlamına gelmez. </p>
  <h2 className="text-xl md:text-2xl font-semibold text-zinc-900 dark:text-white py-5"> Geri Bildirim ve Bilgiler </h2>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu sitede sağladığınız herhangi bir geri bildirim, gizli değil olarak kabul edilecektir. Butaks bu tür bilgileri sınırsız bir şekilde kullanma özgürlüğüne sahip olacaktır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Bu web sitesinde yer alan bilgiler önceden bildirimde bulunmaksızın değiştirilebilir. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Telif Hakkı © 2024 Butaks. Tüm hakları saklıdır. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks Spor Aletleri ve Kent Mobilyaları Limited Şirketi, [Adres]. </p>
  <p className='text-zinc-900 dark:text-white text-base md:text-lg py-1'> Butaks Yasal Ekibi tarafından güncellendi 13.09.2024 </p>
 </div>
</section>
  );
}
export default Termsofuse