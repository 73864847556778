import React from 'react';

const ImageObjectSchema = ({ images, brandName = "Butaks", url }) => { // Props olarak images, brandName ve url alır
  const baseUrl = 'https://www.butaks.com'; // Sitenizin temel URL'si
  const licenseUrl = `${baseUrl}/license`;
  const acquireLicensePageUrl = `${baseUrl}/how-to-use-my-images`;

  if(!images || images.length === 0){
    return null; // images yoksa hiçbir şey render etmezs
  }



  const schemas = images.map((image) => ({
    "@type": "ImageObject",
    "contentUrl": url + image, // Resmin tam URL'si - url prop'u ile birleştirilir
    "license": licenseUrl,
    "acquireLicensePage": acquireLicensePageUrl,
    "creditText": brandName,
    "creator": {
      "@type": "Organization",
      "name": brandName
    },
    "copyrightNotice": brandName,
    "url":url + image


  }));

  return schemas.map((schema, index) => (
    <script
      key={index}
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  ));
};

export default ImageObjectSchema;