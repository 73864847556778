// butaks-frontend-backend\butaks-web\src\utils\stringUtils.js
export const toEnglishCharacters = (text) => {
    const turkishCharacters = {
        'ç': 'c', 'Ç': 'C',
        'ğ': 'g', 'Ğ': 'G',
        'ı': 'i', 'İ': 'I',
        'ö': 'o', 'Ö': 'O',
        'ş': 's', 'Ş': 'S',
        'ü': 'u', 'Ü': 'U'
    };

    return text
        .split('')
        .map(char => turkishCharacters[char] || char)
        .join('')
        .toLowerCase()
        .replace(/[^a-z0-9-]/g, '-'); // Boşlukları ve özel karakterleri tire ile değiştir
};
