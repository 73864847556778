// File: butaks-frontend-backend\butaks-web\src\components\products\ProductGridWithFilter.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import products from '../../datas/Products'
import { toEnglishCharacters } from '../../utils/stringUtils';

function ProductCard({ product }) {
    const navigate = useNavigate();

    const handleTeklifAlClick = () => {
        navigate('/contact'); // İletişim sayfasına yönlendirme
    };

    const handleProductClick = () => {
        const categoryInEnglish = toEnglishCharacters(product.category); // Ensure category is English-friendly
        const slugInEnglish = toEnglishCharacters(product.slug); // Apply English character transformation to slug
        navigate(`/urunlerimiz/${categoryInEnglish}/${slugInEnglish}`);
    };
    

    return (
        <div className='relative bg-white shadow-md rounded-lg overflow-hidden border border-gray-200' 
        onClick={handleProductClick} // Tıklanınca ürün detayına yönlendirme
        >
            {/* Üst Rozetler */}
            <div className='absolute top-2 left-2 flex gap-2'>
                {product.isBestSeller && (
                    <span className='bg-orange-500 text-white px-2 py-1 rounded-full text-xs font-semibold'>
                        En Çok Satan
                    </span>
                )}
                {product.isAdvantageous && (
                    <span className='bg-red-500 text-white px-2 py-1 rounded-full text-xs font-semibold'>
                        Avantajlı Ürün
                    </span>
                )}
            </div>
            
            {/* Ürün Görseli */}
            <img src={product.images[0]} alt={product.name} className='w-full h-auto object-cover' />

            {/* Ürün Bilgisi */}
            <div className='p-4'>
                <h3 className='text-md font-semibold text-gray-800'>{product.name}</h3>
                
                {/* Satıcı Puanı ve Satış Sayısı */}
                <div className='flex items-center text-sm text-gray-500 mt-2'>
                    <span className='flex items-center'>
                        <svg className='w-4 h-4 text-yellow-500' fill="currentColor" viewBox="0 0 24 24">
                            <path d="M12 .587l3.668 7.568 8.332 1.151-6.064 5.973 1.476 8.275L12 18.896l-7.412 4.658L6.064 15.28 0 9.308l8.332-1.151z"/>
                        </svg>
                        {product.rating}
                    </span>
                    <span className='ml-2'>({product.sales}+ satış)</span>
                </div>
                
                {/* Fiyat Bilgisi */}
                <div className='mt-4 flex items-center'>
                    {product.discountPrice ? (
                        <>
                            <span className='text-gray-500 line-through mr-2'>{product.originalPrice} TL</span>
                            <span className='text-lg font-bold text-red-600'>{product.discountPrice} TL</span>
                        </>
                    ) : (
                        <button onClick={handleTeklifAlClick} className='text-lg font-bold text-blue-600 underline'>Teklif Al</button>
                    )}
                </div>
                
                {/* Alt Bilgi */}
                {product.isLowPrice && (
                    <div className='mt-3 text-xs font-medium text-red-600'>
                        Lansman Özel Fiyatı!
                    </div>
                )}
            </div>

            {/* Alt İkonlar */}
            <div className='border-t border-gray-200 px-4 py-2 flex justify-between items-center text-sm text-gray-600'>
                {product.isVerifiedSeller && <span>✅ Yetkili Satıcı</span>}
                {product.isVideoAvailable && <span>🎥 Videolu Ürün</span>}
            </div>
        </div>
    );
}

// Ana Bileşen
function ProductGridWithFilter() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  // Filtrelenmiş ürünleri döndüren işlev (düzeltilmiş)
  const filteredProducts = selectedCategory
      ? products.filter((product) => product.category.toLowerCase() === selectedCategory.toLowerCase()) // Büyük/küçük harfe duyarlılık kaldırıldı
      : products;

    return (
        <section className=''>
            {/* Başlık ve sonuç bilgisi */}
            <div className='text-left mb-6'>
                <h1 className='h1-heading uppercase font-bold'>
                    Modern Park Spor Aletleri
                </h1>
            </div>

            {/* Filtre ve etiketler */}
            <div className='flex flex-wrap justify-left gap-3 mb-4'>
                <span onClick={() => setSelectedCategory(null)} className='cursor-pointer bg-gray-200 text-gray-700 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    🌐 Tüm Ürünler
                </span>
                
                <span onClick={() => setSelectedCategory('Yeni Ürünler')} className='cursor-pointer bg-red-100 text-red-600 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    🔥 Yeni Ürünler
                </span>
                
                <span onClick={() => setSelectedCategory('Ürün Setleri')} className='cursor-pointer bg-yellow-100 text-yellow-600 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    ⭐ Ürün Setleri
                </span>
                
                <span onClick={() => setSelectedCategory('Gym Spor Aletleri')} className='cursor-pointer bg-sky-100 text-sky-600 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    🏬 Gym Spor Aletleri
                </span>
                
                <span onClick={() => setSelectedCategory('Park Spor Aletleri')} className='cursor-pointer bg-pink-100 text-pink-600 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    🎫 Park Spor Aletleri
                </span>
                
                <span onClick={() => setSelectedCategory('Kent Mobilyaları')} className='cursor-pointer bg-orange-100 text-orange-600 rounded-full px-3 py-1 flex items-center gap-1 text-sm font-medium'>
                    🏷️ Kent Mobilyaları
                </span>
            </div>

            {/* Ürün Listesi */}
            {filteredProducts.length === 0 ? (
                <div className='text-center my-8'>
                    <p className='text-gray-600 mb-4'>Bu kısımda herhangi bir ürün bulunmamaktadır. 👀</p>
                    <button onClick={() => setSelectedCategory(null)} className='bg-blue-500 text-white px-4 py-2 rounded'>
                        Tüm Ürünleri Göster
                    </button>
                </div>
            ) : (
                <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6'>
                    {filteredProducts.map((product, index) => (
                        <ProductCard key={index} product={product} />
                    ))}
                </div>
            )}
        </section>
    );
}

export default ProductGridWithFilter;
